import React, { useState, useEffect } from "react";
import { Box, TextField, Button, MenuItem, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { createSubCategory } from "../../../../../API/SubCategoryApi";
import { getProductCategories } from "../../../../../API/CategoryApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../store/actions/actions";

const AddSubCategory = (props) => {
  const [Cat, setCat] = useState([]);
  const [img, setImg] = React.useState({ preview: "", raw: "" });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm(); // Initialize useForm
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const onImageChange = (e) => {
    if (e.target.files.length && e.target.files[0].size <= 3 * 1024 * 1024) {
      const file = e.target.files[0];
      const fileName = file.name.replace(/\s+/g, ""); // Remove white spaces

      // Create a new File object with the cleaned file name
      const cleanedFile = new File([file], fileName, { type: file.type });

      setImg({
        preview: URL.createObjectURL(cleanedFile),
        raw: cleanedFile,
      });
    } else {
      toast.warning("Image is too large, please keep it below 3MB");
    }
  };

  const onSubmit = async (data) => {
    handleLoading(true);
    if (data.name && data.categoryId && img.raw) {
      // Form data is valid, proceed with submission
      const subCat = new FormData();
      subCat.append('subctimg', img.raw);
      subCat.append('name', data.name);
      subCat.append('categoryId', data.categoryId);
      console.log('FormData:', subCat.get('subctimg'), subCat.get('name'), subCat.get('categoryId'));

      try {
        const response = await createSubCategory(subCat);
        if (response.status === true) {
          handleLoading(false);
          toast.success(response.message);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000); // Delay reload by 2 seconds
        } else {
          handleLoading(false);
          toast.error(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      handleLoading(false);
      toast.warning("Fill All The Fields");
    }
  };

  const fetchCat = () => {
    getProductCategories()
      .then((response) => {
        if (response.status) {
          handleLoading(false);
        }
        return response.data;
      })
      .then((data) => {
        setCat(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchCat();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Typography variant="h3" align="center">
            Add a SubCategory
          </Typography>

          <Controller
            name="categoryId"
            control={control}
            defaultValue=""
            rules={{ required: true }} // Add validation rule
            render={({ field }) => (
              <TextField
                select
                fullWidth
                label="Category"
                {...field}
                error={!!errors.categoryId}
                helperText={errors.categoryId ? "Category is required" : ""}>
                {Cat.map((cat) => (
                  <MenuItem key={cat._id} value={cat._id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: true }} // Add validation rule
            render={({ field }) => (
              <TextField
                fullWidth
                label="Enter Sub Category Name"
                {...field}
                error={!!errors.name}
                helperText={errors.name ? "Sub Category Name is required" : ""}
              />
            )}
          />
           <label style={{ marginBlock: "1vh" }}>Add Image(s)</label>
                    <Box>
                      <input multiple type="file" onChange={onImageChange} />
                      <img
                        src={img.preview}
                        width="60px"
                        height="60px"
                        style={{ borderRadius: "55px" }}
                        alt=""
                      />
                    </Box>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-around",
            }}>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </div>
        </Box>
      </form>
    </>
  );
};

export default AddSubCategory;
