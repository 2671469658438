import React from "react";
import OfferCard from "./Cards/OfferCard/OfferCard";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import OfferRow from "./ListItem/OfferRow";
import empty from "../Elements/Images/empty1.gif";
import "./OfferList.css";
import Sort from "../Elements/Sort/Sort";

const OfferList = (props) => {
  const { offersData } = props;
  const { shopId } = useParams();
  const [filteredOffers, setFilteredOffers] = React.useState(offersData);
  const [selectedFilter, setSelectedFilter] = React.useState("All");

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    if (filter === "All") {
      setFilteredOffers(offersData);
    } else if (filter === "Priority") {
      setFilteredOffers(offersData.filter((offer) => offer.isPriority));
    } else if (filter === "Verified") {
      setFilteredOffers(offersData.filter((offer) => offer.isVerified));
    } else if (filter === "Updated") {
      setFilteredOffers(offersData.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn)));
    }
  };

  return (
    <>
      <Box>
        <Typography
          variant="h3"
          align="center"
          fontWeight="600"
          marginTop="5vh">
          Shop Offers
        </Typography>
      </Box>

      {offersData.length > 0 ? (
        <>
          <Box align="right" margin="20px">
            <Link to={`/admin/verifiedShopsList/addOffer/${shopId}`}>
              <Button variant="contained">Add Offer</Button>
            </Link>
          </Box>
          <Box display="flex" justifyContent="start" marginBottom="20px" paddingLeft="10%">
            <Chip
              label="All"
              clickable
              color={selectedFilter === "All" ? "primary" : "default"}
              onClick={() => handleFilterChange("All")}
            />
            <Chip
              label="Priority"
              clickable
              color={selectedFilter === "Priority" ? "primary" : "default"}
              onClick={() => handleFilterChange("Priority")}
              style={{ marginLeft: "10px" }}
            />
            {/* <Chip
              label="Verified"
              clickable
              color={selectedFilter === "Verified" ? "primary" : "default"}
              onClick={() => handleFilterChange("Verified")}
              style={{ marginLeft: "10px" }}
            /> */}
            <Chip
              label="Updated"
              clickable
              color={selectedFilter === "Updated" ? "primary" : "default"}
              onClick={() => handleFilterChange("Updated")}
              style={{ marginLeft: "10px" }}
            />
          </Box>
          <TableContainer
            component={Paper}
            sx={{
              width: "86%",
              margin: "20px auto",
              marginLeft: "9%",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Image</b>
                  </TableCell>
                  {props.isAll && (
                    <TableCell>
                      <b>Shop Name</b>
                    </TableCell>
                  )}
                  <TableCell>
                    <b>Offer Duration</b>
                  </TableCell>
                  <TableCell>
                    <b>Offer ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {/* <TableCell> 
                    <Sort data={offersData} onFilter={setFilteredOffers} /> 
                   </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {(filteredOffers.length > 0 ? filteredOffers : offersData).map(
                  (offer, key) => (
                    <OfferRow
                      key={offer.offerId}
                      imageUrl={offer.imgURL}
                      offerText={offer.discount}
                      linkUrl={offer.linkUrl}
                      offerCode={offer.offerCode}
                      offerId={offer._id}
                      isAll={props.isAll}
                      shopName={offer?.shopId.name}
                      offerStart={offer.offerStart}
                      offerEnd={offer.offerEnd}
                      shopId={offer?.shopId._id}
                      isDisplayed={offer?.isPriority}
                      isVerified={offer?.isVerified}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Box
          display="inlineFlex"
          alignItems="top"
          height="100vh"
          justifyContent="center">
          <Box>
            <Box display="flex" justifyContent="center">
              <img src={empty} width="500px" alt="No Products" />
            </Box>
            <Box align="center">
              <Link to={`/admin/verifiedShopsList/addOffer/${shopId}`}>
                <Button variant="contained">Add Some</Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default OfferList;
