/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { backendApiUrl } from "../../constants";
import {
  TextField,
  Button,
  Typography,
  Autocomplete,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Variants from "../Views/Cards/Variants";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import QuillEditor from "../Elements/RichEditor/QuillEditor";
import { getChildCategoriesBySubCategoryId } from "../../API/ChildCategoryApi";
import AddVariants from "../Views/Modal/AddVariants";
import { getProductCategories } from "../../API/CategoryApi";
import { getSubCategoriesByCategoryId } from "../../API/SubCategoryApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/actions/actions";

export default function AddProduct() {
  const { shopId } = useParams();
  const [childCat, setChildCat] = useState([]);
  const [img, setImg] = useState([]);
  const [thumbnailImg, setThumbnailImg] = useState([]);
  const [vid, setVid] = useState([]);
  const [productDesc, setProductDesc] = useState({ desc: "" });
  const [open, setOpen] = React.useState(false);
  const [prods, setProds] = useState([]);
  const [variants, setVariants] = useState([]);
  const [variantsOpen, setVariantsOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [productId, setProductId] = useState("");
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState("");
  const [subCatId, setSubCatId] = useState("");
  const [childCatId, setChildCatId] = useState("");
  const [subCat, setSubCat] = useState();
  const [isPriority, setIsPriority] = useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleClickOpen = () => {
    setVariantsOpen(true);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onImageChange = async (e) => {
    handleOpen(); // Assuming handleOpen is asynchronous
    const selectedFiles = Array.from(e.target.files);

    const imageObjects = selectedFiles.map((file) => {
      const fileName = file.name.replace(/\s+/g, ""); // Remove white spaces
      const cleanedFile = new File([file], fileName, { type: file.type }); // Create a new File object with the cleaned name

      return {
        preview: URL.createObjectURL(cleanedFile),
        raw: cleanedFile,
      };
    });

    const updatedProdImages = imageObjects.map((e) => e.preview);
    setProds(updatedProdImages);
    setImg(imageObjects);
  };

  const onImageChange1 = (e) => {
    const selectedFiles = Array.from(e.target.files);

    const imageObjects = selectedFiles.map((file) => {
      const fileName = file.name.replace(/\s+/g, ""); // Remove white spaces
      const cleanedFile = new File([file], fileName, { type: file.type }); // Create a new File object with the cleaned name

      return {
        preview: URL.createObjectURL(cleanedFile),
        raw: cleanedFile,
      };
    });

    setThumbnailImg(imageObjects);
  };

  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  const fetchCatData = async (categortyLevel) => {
    handleLoading(true);
    if (categortyLevel === "category") {
      try {
        handleLoading(true);
        const response = await getProductCategories();
        const data = await response.data;
        if (data.length > 0) {
          handleLoading(false);
        }
        setCat(data);
      } catch (error) {
        handleLoading(false);
        console.error("Error fetching data:", error);
      }
    } else if (categortyLevel === "subCategory" && catId) {
      try {
        handleLoading(true);
        const response = await getSubCategoriesByCategoryId(catId._id);
        const data = await response.data;
        if (data.length > 0) {
          handleLoading(false);
        }
        setSubCat(data);
      } catch (error) {
        handleLoading(false);
        console.error("Error fetching data:", error);
      }
    } else {
      try {
        handleLoading(true);
        const response = await getChildCategoriesBySubCategoryId(subCatId._id);
        const data = await response.data;
        if (data.length > 0) {
          handleLoading(false);
        }
        setChildCat(data);
      } catch (error) {
        handleLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    console.log(img);
  }, [img]);
  const handleDiscount = (e) => {
    setDiscount(e);
    setDiscountPrice(Math.round(price - (price * e) / 100));
  };

  const onSubmit = async (data) => {
    handleLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("desc", productDesc.desc);
    formData.append("price", discountPrice);
    formData.append("discount", discount);
    formData.append("shopId", shopId);
    formData.append("childCategoryId", childCatId._id);
    formData.append("childCategoryName", childCatId.name);
    formData.append("subCategoryId", subCatId._id);
    formData.append("subCategoryName", subCatId.name);
    formData.append("categoryId", catId._id);
    formData.append("categoryName", catId.name);
    formData.append("isVerified", true);
    formData.append("mrp", price);
    formData.append("isPriority", isPriority);
    variants.forEach((variant, index) => {
      formData.append(
        `variants[${index}][productSize]`,
        variant.productSize || ""
      );
      formData.append(
        `variants[${index}][productColor]`,
        variant.productColor || ""
      );
      formData.append(
        `variants[${index}][productPrice]`,
        variant.productPrice || ""
      );
    });
    img.forEach((image) => {
      formData.append("productImages", image.raw);
    });

    thumbnailImg.forEach((thumbnail) => {
      formData.append("productThumbnail", thumbnail.raw);
    });

    vid.forEach((video) => {
      formData.append("video", video);
    });

    try {
      const response = await axios.post(`${backendApiUrl}/product/`, formData);
      if (response.data.status === true) {
        setProductId(response.data.data._id);
        console.log(response.data.data._id);
        setTimeout(() => {
          window.location.reload();
        }, 5000); // Delay reload by 2 seconds
        handleLoading(false);
        toast.success(
          <Typography variant="h6" marginTop="2vh">
            Visit this{" "}
            <a
              href={`/#/admin/verifiedShopsList/showProducts/${shopId}/${response.data.data._id}`}
              rel="noopener noreferrer"
              style={{ color: "cyan" }}>
              link
            </a>{" "}
            to update.
          </Typography>,
          {
            autoClose: 3000, // Show toast for 5 seconds
          }
        );
      } else {
        handleLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      handleLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCatData("category");
  }, []);
  useEffect(() => {
    if (catId) fetchCatData("subCategory");
  }, [catId]);
  useEffect(() => {
    if (subCatId) fetchCatData("childCategory");
  }, [subCatId]);

  const handleRemoveVariant = (idToRemove) => {
    setVariants((prevVariants) =>
      prevVariants.filter((variant) => variant.id !== idToRemove)
    );
  };

  const handleRemoveImage = (index) => {
    setImg((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleRemoveThumbnail = (index) => {
    setThumbnailImg((prevThumbnails) =>
      prevThumbnails.filter((_, i) => i !== index)
    );
  };

  return (
    <>
      <Typography align="center" mb="3vh" variant="h3">
        Add Product
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
        }}>
        <Container
          maxWidth="xl"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexFlow: "row",
          }}>
          <Box style={{ margin: "auto" }}>
            {!variantsOpen && variants.length > 0 ? (
              <>
                <Box
                  title="Add More Variants"
                  style={{
                    border: "green solid 2px",
                    display: "flex",
                    justifyContent: "center",
                    width: "200px",
                    height: "50px",
                    marginBlock: "10px",
                  }}>
                  <IconButton onClick={handleClickOpen}>
                    <AddIcon />
                  </IconButton>
                </Box>
                <Box textAlign="center">
                  <Typography>Variants</Typography>
                  {variants.map((v, id) => (
                    <Variants
                      key={id}
                      id={v.id} // Assign a unique identifier for each variant
                      color={v.productColor}
                      price={v.productPrice}
                      size={v.productSize}
                      onRemove={handleRemoveVariant} // Pass remove function to Variants
                    />
                  ))}
                </Box>
                
              </>
            ) : !variantsOpen && variants.length === 0 ? (
              <Box
                style={{
                  border: "green solid 2px",
                  display: "flex",
                  justifyContent: "center",
                  width: "200px",
                  height: "200px",
                }}>
                <IconButton onClick={handleClickOpen} title="Add Variants">
                  <AddIcon />
                </IconButton>
              </Box>
            ) : (
              <AddVariants
                variantsOpen={variantsOpen}
                setVariantsOpen={setVariantsOpen}
                variants={variants}
                setVariants={setVariants}
              />
            )}
                {img.length > 0 && (
                  <Box textAlign="center" m={3}>
                    <Typography>Images</Typography>
                    {img.map((image, index) => (
                      <Box key={index} position="relative" display="inline-block">
                        <img
                          src={image.preview}
                          alt={`product-img-${index}`}
                          style={{ width: "100px", height: "100px", margin: "5px" }}
                        />
                        <IconButton
                          size="small"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "white",
                          }}
                          onClick={() => handleRemoveImage(index)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                )}
                {thumbnailImg.length > 0 && (
                  <Box textAlign="center">
                    <Typography>Thumbnail</Typography>
                    {thumbnailImg.map((thumbnail, index) => (
                      <Box key={index} position="relative" display="inline-block">
                        <img
                          src={thumbnail.preview}
                          alt={`thumbnail-img-${index}`}
                          style={{ width: "100px", height: "100px", margin: "5px" }}
                        />
                        <IconButton
                          size="small"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "white",
                          }}
                          onClick={() => handleRemoveThumbnail(index)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                )}
          </Box>
          <Box
            sx={{
              padding: 5,
              height: "94vh",
              width: "fit-content",
              justifyContent: "center",
              alignItems: "center",
              "& .MuiTextField-root": { m: 1 },
            }}>
            <Container maxWidth="lg">
              <Box align="center">
                <Box maxWidth="sm">
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }} // Add validation rule
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Product Name"
                        title="Product Name"
                        {...field}
                        error={!!errors.name}
                        helperText={
                          errors.name ? "Product Name is required" : ""
                        }
                      />
                    )}
                  />
                  <QuillEditor
                    value={productDesc}
                    heading="Product Description *"
                    onStateChange={setProductDesc}
                    title="Product Description"
                    htmlKey="desc"
                  />
                  <TextField
                    fullWidth
                    label="Price"
                    type="number"
                    title="Price"
                    error={!!errors.price}
                    onChange={(e) => setPrice(e.target.value)}
                    helperText={
                      errors.price
                        ? "Price is required and must be a number"
                        : ""
                    }
                  />

                  <TextField
                    fullWidth
                    label="Discount"
                    type="number"
                    title="Discount"
                    error={!!errors.discount}
                    onChange={(e) => handleDiscount(e.target.value)}
                    helperText={
                      errors.discount
                        ? "Discount is required and must be a number"
                        : `discount price is ${discountPrice}`
                    }
                  />
                  <Autocomplete
                    id="category"
                    options={cat}
                    getOptionLabel={(option) => option.name}
                    value={cat?.find((category) => category === catId) || null}
                    onChange={(event, value) => {
                      setCatId(value ? value : "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Category"
                        placeholder="Select Category"
                      />
                    )}
                  />
                  <Autocomplete
                    id="subcategory"
                    options={subCat}
                    getOptionLabel={(option) => option.name}
                    value={
                      (catId &&
                        subCat?.find(
                          (subcategory) => subcategory === subCatId
                        )) ||
                      null
                    }
                    onChange={(event, value) => {
                      setSubCatId(value ? value : "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Subcategory"
                        placeholder="Select Subcategory"
                      />
                    )}
                  />
                  <Autocomplete
                    id="child-category"
                    options={childCat}
                    getOptionLabel={(option) => option.name}
                    value={
                      (subCat &&
                        childCat?.find(
                          (childCategory) => childCategory === childCatId
                        )) ||
                      null
                    }
                    onChange={(event, value) => {
                      setChildCatId(value ? value : "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Child Category"
                        placeholder="Select Child Category"
                      />
                    )}
                  />
                  <FormControl component="fieldset" sx={{ mb: 2 }}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isPriority}
                            onChange={(e) => setIsPriority(e.target.checked)}
                            color="primary"
                            inputProps={{ "aria-label": "Is Priority" }}
                          />
                        }
                        label="Is Premium"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>

                  <Box marginY="2vh">
                    <label>Images *</label>
                    <Box>
                      <input
                        multiple
                        type="file"
                        title="Product Images"
                        accept="image/*"
                        onChange={onImageChange}
                        required
                      />
                    </Box>
                    <p>{img.length}</p>
                  </Box>

                  <Box marginY="2vh">
                    <label>Thumbnail *</label>
                    <Box>
                      <input
                        type="file"
                        accept="image/*"
                        title="Product Thumbnail"
                        onChange={onImageChange1}
                        required
                      />
                    </Box>

                    <p>{thumbnailImg.length}</p>
                  </Box>

                  <Box sx={{ "& .MuiButton-root": { m: 1 } }}>
                    <Button
                      variant="contained"
                      style={{ width: "250px", marginTop: "30px" }}
                      startIcon={<AddIcon />}
                      type="submit">
                      Add
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        </Container>
      </form>
    </>
  );
}
