import React, { useRef, useEffect, useState } from 'react';
import Table from 'smart-webcomponents-react/table';
import axios from 'axios';
import { backendApiUrl } from '../../constants';
import { QRCodeSVG } from 'qrcode.react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Chip } from '@mui/material';

const Transactions = () => {
    const tableRef = useRef(null);
    const [dataSource, setDataSource] = useState([]);
    const [redeemDataSource, setRedeemDataSource] = useState([]);
    const [shopDataSource, setShopDataSource] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [qrCodeVisible, setQrCodeVisible] = useState(false);
    const [selectedUpiId, setSelectedUpiId] = useState('');
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [selectedTab, setSelectedTab] = useState('transactions');
    const keyboardNavigation = true;
    const grouping = true;

    const columns = [
        { label: 'Shop (Points)', dataField: 'shopInfo', width: 450 },
        { label: 'Name (Mobile)', dataField: 'userInfo', width: 450 },
        { label: 'Points', dataField: 'points', width: 250 },
        { label: 'Created At', dataField: 'createdAt' },
    ];

    const redeemColumns = [
        { label: 'Name (Mobile)', dataField: 'userInfo', width: 300 },
        { label: 'UPI ID', dataField: 'upiId', width: 250 },
        { label: 'Redeem Points', dataField: 'totalPointsRedeem', width: 150 },
        { label: 'CashBack %', dataField: 'currentCashBackPercent', width: 150 },
        { label: 'Amount', dataField: 'cashBackAmount', width: 150 },
        { label: 'Time', dataField: 'createdAt', width: 150, },
        {
            label: 'Action',
            dataField: '_id',
            dataType: 'string',
            width: 100,
            formatFunction(settings) {
                const id = settings.value;
                const row = settings.data.status;
                const upiId = settings.data.upiId || 'N/A';
                console.log(row, upiId, 'Row data');
                const isConfirmed = row === 1;
                settings.template = `
                    <button class="action-button" onclick="handleOpenDialog('${id}', '${upiId}')" style="background-color: ${isConfirmed ? 'gray' : '#4CAF50'}; color: white; border: none; padding: 5px 10px; cursor: ${isConfirmed ? 'not-allowed' : 'pointer'};" ${isConfirmed ? 'disabled' : ''}>${isConfirmed ? 'Confirmed' : 'Confirm'} </button>
                `;
            }
        }
    ];

    const shopColumns = [
        { label: 'Name', dataField: 'name', width: 300 },
        { label: 'Mobile', dataField: 'mobile', width: 250 },
        { label: 'Total Points', dataField: 'totalPoints', width: 150 },
    ];

    useEffect(() => {
        axios.get(backendApiUrl + "/all/transactions")
            .then(response => {
                const data = response.data.data.map(item => ({
                    _id: item._id,
                    shopInfo: item.shopId ? `${item.shopId.name}, (${item.shopId.totalPoints})` : 'N/A',
                    userInfo: item.userId ? `${item.userId.firstName} (${item.userId.mobile})` : 'N/A',
                    points: item.points || 'N/A',
                    isRedeemed: item.isRedeemed || false,
                    createdAt: new Date(item.createdAt).toLocaleString('en-In', {
                        day: 'numeric',
                        month: 'short',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    })
                }));
                setDataSource(data);
                console.log(data, 'Data for transactions');
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        axios.get(backendApiUrl + "/all/redeem")
            .then(response => {
                const data = response.data.data.map(item => ({
                    _id: item._id,
                    userInfo: item.userId ? `${item.userId.firstName} ${item.userId.lastName || ''} (${item.userId.mobile})` : 'N/A',
                    upiId: item.upiId || 'N/A',
                    totalPointsRedeem: item.totalPointsRedeem || 'N/A',
                    currentCashBackPercent: item.currentCashBackPercent || 'N/A',
                    cashBackAmount: item.cashBackAmount || 'N/A',
                    status: item.status || 0,
                    createdAt: new Date(item.createdAt).toLocaleString('en-In', {
                        day: 'numeric',
                        month: 'short',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    })
                }));
                setRedeemDataSource(data);
                console.log(data, 'Data for redeem by user');
            })
            .catch(error => {
                console.error('Error fetching redeem data:', error);
            });
    }, []);

    useEffect(() => {
        if (selectedTab === 'shops') {
            axios.get(`${backendApiUrl}/all/shopsTransactionpoints`)
                .then(response => {
                    const data = response.data.data.map(item => ({
                        _id: item._id,
                        name: item.name,
                        mobile: item.mobile,
                        totalPoints: item.totalPoints
                    }));
                    setShopDataSource(data);
                    console.log(data, 'Data for shops');
                })
                .catch(error => {
                    console.error('Error fetching shop data:', error);
                });
        }
    }, [selectedTab]);

    const handleOpenDialog = (id, upiId) => {
        setSelectedTransaction(id);
        setSelectedUpiId(upiId);
        setQrCodeVisible(true);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedTransaction(null);
        setQrCodeVisible(false);
    };

    const handleConfirm = () => {
        axios.put(`${backendApiUrl}/all/redeem/${selectedTransaction}`, { status: 1 }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log('Transaction confirmed:', response);
                handleCloseDialog();
            })
            .catch(error => {
                console.error('Error confirming transaction:', error);
            });
    };

    window.handleOpenDialog = handleOpenDialog;

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <h1>Transactions</h1>
            </div>
            <div style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', }}>
                <Chip label="Transaction Data" onClick={() => setSelectedTab('transactions')} color={selectedTab === 'transactions' ? 'primary' : 'default'}  style={{marginRight: '20px'}}/>
                <Chip label="Shop Data" onClick={() => setSelectedTab('shops')} color={selectedTab === 'shops' ? 'primary' : 'default'} />
            </div>
            {selectedTab === 'transactions' && (
                <div style={{ paddingLeft: '100px' }}>
                    <Table
                        ref={tableRef}
                        id="table"
                        dataSource={dataSource}
                        grouping={grouping}
                        keyboardNavigation={keyboardNavigation}
                        columns={columns}
                        filtering
                        tooltip
                        columnResize
                        sortMode="one"
                        paging
                        className="custom-table table-hover thead-dark"
                    />
                </div>
            )}
            {selectedTab === 'shops' && (
                <div style={{ paddingLeft: '100px' }}>
                    <Table
                        ref={tableRef}
                        id="shopTable"
                        dataSource={shopDataSource}
                        grouping={grouping}
                        keyboardNavigation={keyboardNavigation}
                        columns={shopColumns}
                        filtering
                        tooltip
                        columnResize
                        sortMode="one"
                        paging
                        className="custom-table table-hover thead-dark"
                    />
                </div>
            )}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Transfer</DialogTitle>
                <DialogContent>
                    <p>Are you sure you have transferred the CashBack Amount?</p>
                    {qrCodeVisible && selectedUpiId !== 'N/A' && (
                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                            <QRCodeSVG value={`${selectedUpiId}`} size={200} />
                            <p style={{ marginTop: 5 }}>Scan to Pay</p>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
                    <Button onClick={handleConfirm} color="primary">Confirm</Button>
                </DialogActions>
            </Dialog>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <h1>Redeem by User</h1>
            </div>
            <div style={{ paddingLeft: '100px' }}>
                <Table
                    ref={tableRef}
                    id="redeemTable"
                    dataSource={redeemDataSource}
                    grouping={grouping}
                    keyboardNavigation={keyboardNavigation}
                    columns={redeemColumns}
                    filtering
                    tooltip
                    columnResize
                    sortMode="one"
                    paging
                    className="custom-table table-hover thead-dark"
                    rowClass={(row) => row.data.status === 1 ? 'confirmed-row' : ''}
                />
            </div>
        </>
    );
};

// Add CSS for confirmed row
const styles = document.createElement('style');
styles.innerHTML = `
    .confirmed-row {
        background-color: lightgreen !important;
    }
`;
document.head.appendChild(styles);

export default Transactions;

