import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/actions/actions";
import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  TextField,
  Modal,
  IconButton,
  Chip,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import empty from "../../Components/Elements/Images/empty1.gif";
import { backendApiUrl } from "../../constants";
import { Link } from "react-router-dom";
import axios from "axios";


const Offers = () => {
  const [originalData, setOriginalData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortByPriority, setSortByPriority] = useState(false); // Toggle sorting criterion
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [open, setOpen] = useState(false); // State for modal visibility
  const [selectedOfferId, setSelectedOfferId] = useState(null); // State for selected offer ID
  const [selectedChip, setSelectedChip] = useState("All"); // State for selected chip
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
    setIsLoading(value);
  };

  const fetchOffers = async () => {
    handleLoading(true);
    try {
      const response = await fetch(backendApiUrl + "/all/offers", {
        method: "GET",
      });

      const data = await response.json();

      if (data.status === true) {
        toast.success(data.message);

        if (data.data && data.data.length > 0) {
          // Ensure all items have required fields
          const sanitizedData = data.data.map((item) => ({
            ...item,
            updatedOn: item.updatedOn || "1970-01-01", // Default if missing
            isPriority: item.isPriority ?? false, // Default to false if missing
          }));
          setOriginalData(sanitizedData);
          setOffersData(sanitizedData);
        } else {
          toast.error("No offers found.");
          setOriginalData([]);
          setOffersData([]);
        }
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(`Error fetching offers: ${err.message}`);
    } finally {
      handleLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterOffers(event.target.value, selectedChip); // Filter with current chip selection
  };

  const filterOffers = (query, chip) => {
    let filteredData = originalData;

    if (query) {
      filteredData = filteredData.filter((offer) =>
        offer.shopId.name.toLowerCase().includes(query.toLowerCase())
      );
    }

    switch (chip) {
      case "Live":
        filteredData = filteredData.filter((offer) => {
          const today = new Date();
          const offerEnd = new Date(offer.offerEnd);
          return offerEnd >= today;
        });
        break;
      case "Priority":
        filteredData = filteredData.filter((offer) => offer.isPriority);
        break;
      default:
        break;
    }

    setOffersData(filteredData);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(backendApiUrl + `/offers/${selectedOfferId}`);
      toast.success("Offer deleted successfully!");
      handleClose();
      const updatedData = originalData.filter((offer) => offer._id !== selectedOfferId);
      setOriginalData(updatedData);
      filterOffers(searchQuery, selectedChip); // Update offersData based on selected chip
    } catch (error) {
      toast.error("Error deleting offer: " + error.message);
    }
  };

  const handleOpen = (offerId) => {
    setSelectedOfferId(offerId);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handlePriorityChange = async (offerId, isPriority) => {
    handleLoading(true);
    const formData = new FormData();
    formData.append("isPriority", !isPriority);

    try {
      const response = await axios.put(
        backendApiUrl + `/offers/${offerId}`,
        formData
      );
      handleLoading(false);

      if (response.data.status === true) {
        toast.success(`${response.data.message}`);
        const updatedData = originalData.map((offer) =>
          offer._id === offerId ? { ...offer, isPriority: !isPriority } : offer
        );
        setOriginalData(updatedData);
        filterOffers(searchQuery, selectedChip); // Update offersData based on selected chip
      } else toast.error(`${response.data.message}`);
    } catch (error) {
      handleLoading(false);
      toast.error("Error updating offer: " + error.message);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  useEffect(() => {
    filterOffers(searchQuery, selectedChip);
  }, [searchQuery, selectedChip]);

  const handleChipChange = (chip) => {
    setSelectedChip(chip);
    filterOffers(searchQuery, chip);
  };

  const columns = [
    { field: "imgURL", headerName: "Image", width: 150, renderCell: (params) => <img src={params.value || "/no-image.jpg"} alt="Offer" height={50} /> },
    { field: "shopId", headerName: "Shop Name", width: 250, valueGetter: (params) => params.name },
    {
      field: "offerStart", headerName: "Offer Start", width: 150, valueFormatter: (params) => {
        const date = new Date(params);
        return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleDateString('en-In', { day: 'numeric', month: 'short' });
      }
    },
    {
      field: "offerEnd", headerName: "Offer End", width: 150, valueFormatter: (params) => {
        const date = new Date(params);
        return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleDateString('en-In', { day: 'numeric', month: 'short' });
      }
    },
    { field: "_id", headerName: "Offer ID", width: 250 },
    {
      field: "actions",
      headerName: "Actions",
      width: 280,
      renderCell: (params) => (
        <>
          <Link to={`/admin/verifiedShopsList/editOffers/${params.row.shopId._id}/${params.row._id}`}>
            <IconButton color="primary">
              <EditIcon />

            </IconButton>
            
          </Link>
          <IconButton
            color="secondary"
            onClick={() => handleOpen(params.row._id)}
            style={{ marginLeft: "10px" }}
          >
            <DeleteIcon />
          </IconButton>
          <Switch
            checked={params.row.isPriority}
            onChange={() => handlePriorityChange(params.row._id, params.row.isPriority)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            // style={{ marginLeft: "10px" }}
          />
          {/* <Switch /> */}

        </>
      ),
    },
  ];

  return (
    <div>
      <Box align="center">
        <h1>Offers </h1>
      </Box>
      <Box align="right" margin="20px">
        <Link to={`/admin/verifiedShopsList/`}>
          <Button variant="contained">Add Offer</Button>
        </Link>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginX="100px"
        marginBottom="20px"
      >
        <TextField
          label="Search by Shop Name"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ width: "300px" }}
        />
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Chip
            label="All"
            clickable
            color={selectedChip === "All" ? "primary" : "default"}
            onClick={() => handleChipChange("All")}
          />
          <Chip
            label="Live"
            clickable
            color={selectedChip === "Live" ? "primary" : "default"}
            onClick={() => handleChipChange("Live")}
          />
          <Chip
            label="Priority"
            clickable
            color={selectedChip === "Priority" ? "primary" : "default"}
            onClick={() => handleChipChange("Priority")}
          />
        </Box>
      </Box>
      {/* <Box display="flex" justifyContent="space-between" alignItems="center" marginX="100px" marginBottom="20px">	
        <TextField
          label="Search by Shop Name"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ width: "300px" }}
        />
      </Box> */}
      {offersData.length > 0 ? (
        <Box sx={{ width: '90%', paddingLeft: '100px' }}>
          <DataGrid
            rows={offersData}
            columns={columns}
            pageSize={5}
            // rowsPerPageOptions={[}
            getRowId={(row) => row._id} // Specify custom id for each row
          />
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
          <Box textAlign="center">
            <Typography variant="h6">No offers found</Typography>
            <img src={empty} width="500px" alt="No Products" />
          </Box>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description">
        <Box sx={modalStyle}>
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            component="h2">
            Confirm Delete
          </Typography>
          <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this offer? This action cannot be
            undone.
          </Typography>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

// Modal styling
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default Offers;