// CategoryApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";
import { toast } from "react-toastify";

const baseURL = backendApiUrl;

const SubCategoryApi = axios.create({
  baseURL,
  // headers: {
  //   "Content-Type": "application/json",
  // },
});

// Add an interceptor to include the token in the Authorization header for all requests
SubCategoryApi.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token"); // Assuming you store the token in localStorage
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Define your category-related API endpoints
export const getProductSubCategories = async () => {
  try {
    const response = await SubCategoryApi.get("/productSubCategory");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSubCategoriesByCategoryId = async (id) => {
  try {
    const response = await SubCategoryApi.get(
      `/productSubCategory/${id}/category`
    );
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSubCategory = async (subcategoryData) => {
  console.log("SubCategoryData", subcategoryData);
  console.log("Array Data", subcategoryData.arrayData); // Assuming arrayData is a property of subcategoryData

  try {
    const response = await SubCategoryApi.post(
      `/productSubCategory/`, subcategoryData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSubCategory = async (id, userId) => {
  try {
    const response = await SubCategoryApi.delete(`/productSubCategory/${id}`, {
      data: { userId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editSubCategoriesById = async (id, data) => {
  try {
    const response = await SubCategoryApi.put(
      `/productSubCategory/${id}`,
      data
    );
    toast.success("Sub Category Updated Successfully");
    return response.data;
  } catch (error) {
    toast.error("Sub Category Update Failed", error);
    throw error;
  }
};

export default SubCategoryApi;
