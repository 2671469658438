import React, { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  Container,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import axios from "axios";
import LocationPicker from "../Elements/LocationPicker/LocationPicker";
import { getShopById } from "../../API/ShopApi";
import { backendApiUrl } from "../../constants";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/actions/actions";
import { Quill } from "react-quill";
import QuillEditor from "../Elements/RichEditor/QuillEditor";

export default function VerifiedShopTemplate() {
  const [img, setImg] = useState({ preview: "", raw: "" });
  const [bgImg, setBgImg] = useState({ preview: "", raw: "" });
  const { id } = useParams();
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [user, setUser] = useState({
    name: "",
    kartaName: "",
    aadharNo: "",
    password: "",
    panNo: "",
    desc: "",
    gstNo: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    shopImg: "",
    shopBgImg: "",
    location: [],
    email: "",
    websiteLink: "",
    addressKey: "",
    socialMediaLinks: [],
    itemCategories: [], 
  });
  const [categories, setCategories] = useState([]); 
  const [isPriority, setIsPriority] = useState(false);
  const [isChoosingLocation, setIsChoosingLocation] = useState(false);
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const defaultSocialMediaLinks = [
    { type: "instagram", url: "" },
    { type: "whatsapp", url: "" },
    { type: "facebook", url: "" },
  ];

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://server.atf-labs.com:3000/productCategory", {
        headers: {
          Authorization: "Bearer YOUR_TOKEN_HERE", // Replace with your token
        },
      });
      if (response.data.status) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    if (user.socialMediaLinks.length !== 3) {
      const updatedLinks = defaultSocialMediaLinks.map((defaultLink) => {
        const existingLink = user.socialMediaLinks.find(
          (link) => link.type === defaultLink.type
        );
        return existingLink || defaultLink;
      });
      setUser((prevUser) => ({
        ...prevUser,
        socialMediaLinks: updatedLinks,
      }));
    }
  }, [user.socialMediaLinks]);

  const imageChangeHandler = (e, setImage) => {
    if (e.target.files.length && e.target.files[0].size <= 3 * 1024 * 1024) {
      const file = e.target.files[0];
      const fileName = file.name.replace(/\s+/g, ""); // Remove all white spaces from the file name

      // Create a new File object with the cleaned file name
      const cleanedFile = new File([file], fileName, { type: file.type });

      setImage({
        preview: URL.createObjectURL(cleanedFile),
        raw: cleanedFile,
      });
    } else {
      toast.warning("Image is too large, please keep it below 3MB");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleLoading(true);
    const formData = new FormData();

    // Append form fields
    Object.entries(user).forEach(([key, value]) => {
      if (
        [
          "name",
          "email",
          "kartaName",
          "password",
          "desc",
          "aadharNo",
          "panNo",
          "gstNo",
          "mobile",
          "address",
          "state",
          "city",
          "pincode",
          "websiteLink",
          "location",
          "socialMediaLinks",
          "addressKey",
          "itemCategories",
        ].includes(key)
      ) {
        if (key === "location") {
          formData.append("location[0]", coordinates.lat || user.location[0]);
          formData.append("location[1]", coordinates.lng || user.location[1]);
        } else if (key === "socialMediaLinks") {
          let index = 0;
          user.socialMediaLinks.forEach((link) => {
            if (link && link.url) {
              formData.append(`socialMediaLinks[${index}][type]`, link.type);
              formData.append(`socialMediaLinks[${index}][url]`, link.url);
              index++;
            }
          });
        } else if (key === "itemCategories") {
          value.forEach((categoryId, index) => {
            formData.append(`itemCategories[${index}]`, categoryId);
          });
        } else {
          if (value) {
            formData.append(key, value);
          }
        }
      }
    });

    // Append images
    formData.append("shopImage", img.raw || user.shopImg);
    formData.append("shopBgImage", bgImg.raw || user.shopBgImg);
    formData.append("isPriority", isPriority);

    try {
      const response = await axios.put(
        `${backendApiUrl}/shops/${id}`,
        formData
      );
      if (response.data.status === true) {
        handleLoading(false);
        toast.success(response.data.message);
      } else {
        handleLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      handleLoading(false);
      toast.error("Error submitting form");
    }
  };

  const fetchData = useCallback(async () => {
    handleLoading(true);
    try {
      const response = await getShopById(id);
      const data = response.data;
      if (response.status == true) {
        handleLoading(false);
        setUser(data);
        console.log("Shop Data:", user.location);
      }
    } catch (error) {
      console.error("Error fetching shop data", error);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
    fetchCategories();
  }, [fetchData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log("User Data:", coordinates);
  }, [coordinates]);
  const handleSocialMediaChange = (index, value) => {
    const updatedLinks = [...user.socialMediaLinks];
    updatedLinks[index].url = value;
    setUser((prevUser) => ({
      ...prevUser,
      socialMediaLinks: updatedLinks,
    }));
  };

  const handleCategoryChange = (event) => {
    setUser((prevUser) => ({
      ...prevUser,
      itemCategories: [event.target.value], // Update itemCategories with the selected category
    }));
  };

  const handleLocationSelect = (location) => {
    setCoordinates(location);
    setIsChoosingLocation(true);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          padding="20px"
          align="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "fit-content",
            width: "100%",
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}>
          <Container maxWidth="lg">
            <Typography variant="h3" marginBottom="5vh">
              {user.name}
            </Typography>
            <Grid container spacing={3}>
              <Grid item sm={12} lg={6}>
                <Box
                  className="wrapper"
                  sx={{
                    backgroundColor: "black",
                    position: "relative",
                    border: "5px solid #fff",
                    borderRadius: "10%",
                    width: "500px",
                    height: "470px",
                  }}>
                  {bgImg.preview ? (
                    <img
                      height="500px"
                      width="500px"
                      src={bgImg.preview}
                      alt="Shop Background"
                      className="Pimage"
                    />
                  ) : (
                    <img
                      height="500px"
                      width="500px"
                      src={`${user.shopBgImg}`}
                      alt="Shop Background"
                    />
                  )}
                  <input
                    type="file"
                    className="onClickBg"
                    onChange={(e) => imageChangeHandler(e, setBgImg)}
                  />
                  <Box
                    className="wrapper"
                    sx={{
                      backgroundColor: "black",
                      position: "absolute",
                      border: "5px solid #fff",
                      borderRadius: "10%",
                      width: "170px",
                      height: "170px",
                      top: "160px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      overflow: "hidden",
                      zIndex: "20",
                    }}>
                    {img.preview ? (
                      <img
                        height="190vh"
                        src={img.preview}
                        alt="Shop"
                        className="Pimage"
                      />
                    ) : (
                      <img
                        height="190vh"
                        src={`${user.shopImg}`}
                        alt="Shop"
                        style={{ width: "100%" }}
                      />
                    )}
                    <input
                      type="file"
                      className="onClick"
                      onChange={(e) => imageChangeHandler(e, setImg)}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  width="100%"
                  marginTop="5vh">
                  <Button
                    href={`/#/admin/verifiedShopsList/showProducts/${id}`}>
                    Show All Products
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box marginY="2vh">
                  <Grid container spacing={3}>
                    {[
                      "name",
                      "email",
                      "kartaName",
                      "password",
                      "desc",
                      "aadharNo",
                      "panNo",
                      "gstNo",
                      "mobile",
                      "address",
                      "state",
                      "city",
                      "pincode",
                      "addressKey",
                    ].map((field) =>
                      field === "desc" ? (
                        <Grid item lg={12} key={field}>
                          <QuillEditor
                            value={user}
                            onStateChange={setUser}
                            heading="Shop Description"
                            title="Shop Description"
                            htmlKey="desc"
                          />
                        </Grid>
                      ) : (
                        <Grid item lg={6} key={field}>
                          <TextField
                            size="medium"
                            name={field}
                            label={field.replace(/^\w/, (c) => c.toUpperCase())}
                            required={
                              field !== "aadharNo" &&
                              field !== "panNo" &&
                              field !== "gstNo" &&
                              field !== "desc" &&
                              field !== "location"
                            }
                            value={user[field]}
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                      )
                    )}
                   <Grid item xs={12}>
                      <Typography variant="h6" marginTop="2vh">
                        Item Categories
                      </Typography>
                      <Select
                        value={user.itemCategories[0] || ""}
                        onChange={handleCategoryChange}
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value="" disabled>
                          Select a Category
                        </MenuItem>
                        {categories.map((category) => (
                          <MenuItem key={category._id} value={category._id}>
                            <img
                              src={category.thumbnail}
                              alt={category.name}
                              style={{ width: "30px", marginRight: "10px" }}
                            />
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl component="fieldset" sx={{ mb: 2 }}>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isPriority}
                                onChange={(e) =>
                                  setIsPriority(e.target.checked)
                                }
                                color="primary"
                                inputProps={{ "aria-label": "Is Priority" }}
                              />
                            }
                            label="Is Premium"
                            labelPlacement="top"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" marginTop="2vh">
                        Social Media Links
                      </Typography>
                      {user.socialMediaLinks.map((link, index) => (
                        <TextField
                          key={link ? link.type : index}
                          label={
                            link && link.type
                              ? link.type.charAt(0).toUpperCase() +
                                link.type.slice(1)
                              : ""
                          }
                          value={link ? link.url : ""}
                          onChange={(e) =>
                            handleSocialMediaChange(index, e.target.value)
                          }
                          fullWidth
                          sx={{ marginBottom: "1vh" }}
                        />
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" marginTop="2vh">
                        Website Link
                      </Typography>
                      <TextField
                        name="websiteLink"
                        label="Website"
                        value={user.websiteLink}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          width: "auto",
                        }}>
                        Current Location: {user.location[0]}, {user.location[1]}
                        {isChoosingLocation ? (
                          <LocationPicker
                            location={user.location}
                            setSelection={setIsChoosingLocation}
                            onSelectLocation={handleLocationSelect}
                          />
                        ) : (
                          <>
                            <Typography>Change Location</Typography>
                            <IconButton
                              color={
                                coordinates.lat && coordinates.lng
                                  ? "success"
                                  : "default"
                              }
                              onClick={() => setIsChoosingLocation(true)}
                              sx={{ marginInline: "41%", width: "40px" }}>
                              <AddLocationIcon />
                            </IconButton>
                            {coordinates.lat && coordinates.lng && (
                              <Typography>
                                Selected coordinates: {coordinates.lat},{" "}
                                {coordinates.lng}
                              </Typography>
                            )}
                          </>
                        )}
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Box sx={{ "& .MuiButton-root": { m: 1 } }}>
                        <Button
                          variant="contained"
                          type="submit"
                          startIcon={<UpdateIcon />}>
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </form>
    </>
  );
}
