import React, { useState } from "react";
import { TableRow, TableCell, IconButton, Avatar, Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
// import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import CheckIcon from "@mui/icons-material/Check";
import Warning from "../Modal/Warning";
// import MoreVertItems from "../MoreVertItems";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { backendApiUrl } from "../../../constants";

const ShopItem = ({ id, imgUrl, Shop_Name, Karta_Name, Mobile }) => {
  const [open, setOpen] = useState(false);

  // Open delete warning modal
  const handleDeleteClick = () => {
    setOpen(true);
  };

  // Close delete warning modal
  const handleClose = () => {
    setOpen(false);
  };

  // Handle shop verification
  const handleVerifyClick = async (e) => {
    e.stopPropagation();
    try {
      const formData = new FormData();
      formData.append("isVerified", true);

      const response = await axios.patch(
        backendApiUrl +  `/shops/${id}/verify`,
        formData
      );

      if (response.data.status === true) {
        toast.success("Shop verified successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000); // Delay reload by 2 seconds
      } else {
        toast.error("Failed to verify shop.");
      }
    } catch (error) {
      toast.error("Error verifying shop: " + error.message);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Avatar
            src={imgUrl || "/no-image.jpg"}
            alt={Shop_Name || "No Image"}
            sx={{ width: 60, height: 60 }}
          />
        </TableCell>
        <TableCell>{Shop_Name}</TableCell>
        <TableCell>{Karta_Name}</TableCell>
        <TableCell>{Mobile}</TableCell>

        <TableCell>
          <Box display="flex" alignItems="center">
            <Link
              to={`/admin/verifiedShopsList/shopDetails/${id}`}
              style={{ textDecoration: "none", color: "inherit" }}>
              <IconButton title="View">
                <VisibilityIcon sx={{ color: "blue" }} />
              </IconButton>
            </Link>

            {/* Verify Button */}
            <IconButton title="Verify Shop" onClick={handleVerifyClick}>
              <CheckIcon sx={{ color: "green" }} />
            </IconButton>

            {/* Delete Button */}
            <IconButton title="Delete" onClick={handleDeleteClick}>
              <DeleteIcon sx={{ color: "red" }} />
            </IconButton>

            {/* <MoreVertItems id={id} /> */}
          </Box>
        </TableCell>
      </TableRow>

      {/* Warning modal for delete confirmation */}
      <Warning open={open} onClose={handleClose} id={id} component="Shop" />
    </>
  );
};

export default ShopItem;
