/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  BottomNavigation,
  BottomNavigationAction,
  IconButton,
  Typography,
  Chip,
} from "@mui/material";
import "boxicons";
import UnVerifiedShopProduct from "./UnVerifiedShopProduct";
import { Link, useParams, useNavigate } from "react-router-dom";
import ProductList from "./ProductList";
import Banner from "./Banner";
import InventoryIcon from "@mui/icons-material/Inventory";
import PagesIcon from "@mui/icons-material/Pages";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { SideBarWidth } from "../../constants";
import { getShopById } from "../../API/ShopApi";
import { Grid } from "@mui/material";
import ShopReels from "./Reels/ShopReels";
import { Discount, PlayArrow } from "@mui/icons-material";
import OfferList from "./OffersList";
import { toast } from "react-toastify";
import { getOfferByShopId } from "../../API/OfferApi";
import { useDispatch, useSelector } from "react-redux";
import { setValue } from "../../store/actions/actions";

export default function VerifiedShopTemplate(props) {
  const [user, setUser] = useState({
    name: "",
    kartaName: "",
    aadharNo: "",
    panNo: "",
    gstNo: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    location: [],
    shopThumbnail: "",
    shopBgThumbnail: "",
  });
  const [offersData, setOffersData] = useState([]);

  const { shopId } = useParams();
  const navigate = useNavigate(); // For redirection

  const fetchData = async () => {
    await getShopById(shopId)
      .then((response) => {
        return response;
      })
      .then((data) => {
        setUser(data.data);
        console.log(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchOffers = async () => {
    const response = await getOfferByShopId(shopId); // Pass correct shopId
    if (response.status === true) {
      // Sorting the offers by updatedAt (latest first)
      const sortedOffers = response.data.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );

      setOffersData(sortedOffers); // Set sorted data
    } else {
      toast.error(response.message || "Failed to fetch offers");
    }
  };

  const dispatch = useDispatch();
  const value = useSelector((state) => state.value.value);
  console.log(value);

  const handleChange = (event, value) => {
    dispatch(setValue(value || "productList"));
  };

  const redirectToNotification = () => {
    const notificationType = "shop"; // Example type
    navigate(`/admin/customNotification?id=${shopId}&type=${notificationType}`);
  };

  useEffect(() => {
    fetchData();
    fetchOffers();
  }, []);
  // const img = http://api.litchies.com/images/${user.shopImg};

  return (
    <>
      <Box sx={{ height: "fit-content", align: "center" }}>
        <Box
          sx={{
            height: "50vh",
            backgroundImage: `url(${user.shopBgImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}>
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 10,
            }}>
            <IconButton
              title="Send Shop Notification"
              onClick={() => redirectToNotification()}
              color="primary"
              aria-label="add offer">
              <box-icon name="bell-plus" color="currentColor"></box-icon>
            </IconButton>
          </Box>
        </Box>
        <Link to={`/admin/verifiedShopsList/${shopId}`}>
          <Box
            sx={{
              height: "300px",
              width: "300px",
              backgroundColor: "crimson",
              position: "absolute",
              zIndex: 2,
              marginLeft: "5%",
              borderRadius: "50%",
              top: "30%",
              left: "40%",
            }}>
            <Box
              sx={{
                height: "290px",
                width: "290px",
                backgroundImage: `url(${user.shopThumbnail})`,
                position: "absolute",
                zIndex: 3,
                marginLeft: "5%",
                borderRadius: "50%",
                top: "1.5%",
                backgroundPosition: "center",
                backgroundSize: "cover",
                left: "-3.5%",
              }}></Box>
          </Box>
        </Link>
        <Grid
          container
          spacing={2}
          sx={{
            marginLeft: `${SideBarWidth}`,
            width: "1000px",
            marginInline: "auto",
          }}>
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              variant="h3"
              sx={{ marginTop: "20vh" }}>
              {user.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.name && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Shop Name:{" "}
              <Typography variant="p" color="black">
                {user.name}
              </Typography>
            </Typography>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              textAlign: "center",
              display: `${!user.kartaName && "none"}`,
            }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Karta Name:{" "}
              <Typography variant="p" color="black">
                {user.kartaName}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.email && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              E-mail:{" "}
              <Typography variant="p" color="black">
                {user.email}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "center",
              display: `${!user.aadharNo && "none"}`,
            }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Aadhar No.:{" "}
              <Typography variant="p" color="black">
                {user.aadharNo}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.address && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Address:{" "}
              <Typography variant="p" color="black">
                {user.address}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.gstNo && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Gst No.:{" "}
              <Typography variant="p" color="black">
                {user.gstNo}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.mobile && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Mobile:{" "}
              <Typography variant="p" color="black">
                {user.mobile}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.panNo && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Pan No.:{" "}
              <Typography variant="p" color="black">
                {user.panNo}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.city && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              City:{" "}
              <Typography variant="p" color="black">
                {user.city}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.state && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              State:{" "}
              <Typography variant="p" color="black">
                {user.state}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.pincode && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Pincode:{" "}
              <Typography variant="p" color="black">
                {user.pincode}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "center",
              display: `${!user.location && "none"}`,
            }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Coordinates:{" "}
              <Typography variant="p" color="black">
                {user.location}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.name && "none"}` }}>
            <Typography
              fontSize="22px"
              my="2vh"
              color="grey"
              style={{ maxWidth: "auto" }}>
              Shop Description:{" "}
              <Typography
                variant="body1"
                color="black"
                dangerouslySetInnerHTML={{ __html: user.desc }}
              />
            </Typography>
          </Grid>
          {/* <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.pincode && "none"}` }}>
            <Button
              variant="contained"
              color="primary"
              href={`#/admin/verifiedShopsList/addOffer/${shopId}`}>
              Add Offers
            </Button>
          </Grid> */}
        </Grid>
        <Grid xs={12} paddingTop="2vh">
          <Box width="100%" align="center">
            {[
              { label: "Unverified Products", value: "un-verifiedProducts", icon: <UnpublishedIcon /> },
              { label: "Product List", value: "productList", icon: <InventoryIcon /> },
              { label: "Banner", value: "banner", icon: <PagesIcon /> },
              { label: "Shop Reels", value: "ShopReels", icon: <PlayArrow /> },
              { label: "Offers", value: "Offers", icon: <Discount /> },
            ].map((item) => (
              <Chip
                key={item.value}
                label={item.label}
                icon={item.icon}
                onClick={() => handleChange(null, item.value)}
                color={value === item.value ? "primary" : "default"}
                sx={{ margin: 1 }}
              />
            ))}
          </Box>
          <Box
            display={value === "un-verifiedProducts" ? "visible" : "none"}
            title="Un-Verified Products"
            ease>
            <UnVerifiedShopProduct />
          </Box>
          <Box
            display={value === "productList" ? "visible" : "none"}
            title="ProductList">
            <ProductList />
          </Box>
          <Box display={value === "banner" ? "visible" : "none"} title="Banner">
            <Banner />
          </Box>
          <Box
            title="ShopReels"
            display={value === "ShopReels" ? "visible" : "none"}>
            <ShopReels />
          </Box>
          <Box title="Offers" display={value === "Offers" ? "visible" : "none"}>
            <OfferList offersData={offersData} />
          </Box>
        </Grid>
      </Box>
    </>
  );
}
