import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, MenuItem, Select, Typography, Autocomplete } from '@mui/material';
import { width } from '@mui/system';
import React, { useRef, useState, useEffect } from 'react';
import Table from 'smart-webcomponents-react/table';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { backendApiUrl } from '../../constants';
import { toast } from 'react-toastify';
import { set } from 'lodash';

const HighlightStory = () => {
  const [open, setOpen] = useState(false);
  const [showAddAudioFields, setShowAddAudioFields] = useState(false);
  const [audioName, setAudioName] = useState('');
  const [error, setError] = useState(false);
  const tableRef = useRef(null);
  const keyboardNavigation = true;
  const [openAddStory, setOpenAddStory] = useState(false);
  const [storyName, setStoryName] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [storyImages, setStoryImages] = useState([]);
  const [storyMetaData, setStoryMetaData] = useState([{ key: '', type: '' }]);
  const [selectedAudio, setSelectedAudio] = useState('');
  const [selectedMainCategory, setSelectedMainCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedShop, setSelectedShop] = useState('');
  const [shops, setShops] = useState([]);
  const [audioFiles, setAudioFiles] = useState([]);
  const [audioFile, setAudioFile] = useState(null);
  const [products, setProducts] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedOffer, setSelectedOffer] = useState('');
  const [stories, setStories] = useState([]);
  const [storyList, setStoryList] = useState([{ type: '', product: '', offer: '', other: '' }]);

  useEffect(() => {
    axios.get(backendApiUrl + "/all/shopsmetadata")
      .then(response => {
        setShops(response.data.data);
      })
      .catch(error => {
        console.error("There was an error fetching the shops!", error);
      });
  }, []);

  useEffect(() => {
    fetchAudioFiles();
    fetchStories();
  }, []);

  const fetchAudioFiles = async () => {
    try {
      const response = await axios.get(backendApiUrl + "/audios");
      if (Array.isArray(response.data.data)) {
        setAudioFiles(response.data.data);
      } else {
        setAudioFiles([]);
      }
    } catch (error) {
      console.error("There was an error fetching the audio files!", error);
      setAudioFiles([]);
    }
  };

  const fetchStories = async () => {
    try {
      const response = await axios.get(backendApiUrl + "/stories");
      if (Array.isArray(response.data.data)) {
        setStories(response.data.data);
      } else {
        setStories([]);
      }
    } catch (error) {
      console.error("There was an error fetching the stories!", error);
      setStories([]);
    }
  };

  const fetchProducts = async (shopId) => {
    try {
      const response = await axios.get(`${backendApiUrl}/all/productsmetadata?shopId=${shopId}`);
      setProducts(response.data.data);
    } catch (error) {
      console.error("There was an error fetching the products!", error);
      setProducts([]);
    }
  };

  const fetchOffers = async (shopId) => {
    try {
      const response = await axios.get(`${backendApiUrl}/all/offersmetadata?shopId=${shopId}`);
      setOffers(response.data.data);
    } catch (error) {
      console.error("There was an error fetching the offers!", error);
      setOffers([]);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowAddAudioFields(false);
    setAudioName('');
    setError(false);
  };

  const handleAddAudioOpen = () => {
    setShowAddAudioFields(true);
  };

  const handleDelete = async (audioId) => {
    try {
      const response = await axios.delete(`${backendApiUrl}/audios/${audioId}`, {
        headers: {
          "Authorization": "null",
          "Content-Type": "application/json"
        },
        data: JSON.stringify({
          "userId": ""
        })
      });
      if (response.status === 200) {
        toast.success("Audio file deleted successfully!");
        fetchAudioFiles();
      } else {
        toast.error("Failed to delete audio file.");
      }
    } catch (error) {
      console.error("There was an error deleting the audio file!", error);
      toast.error("Error deleting audio file: " + error.message);
    }
  };

  const handleSaveAudio = async () => {
    if (!audioName || !audioFile) {
      setError(true);
      return;
    }
    const form = new FormData();
    form.append("audio", audioFile);
    form.append("isActive", "true");
    form.append("name", audioName);
    try {
      const response = await axios.post(backendApiUrl + "/audios/", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        toast.success("Audio file uploaded successfully!");
        fetchAudioFiles();
        setShowAddAudioFields(false);
        setAudioName("");
        setAudioFile(null);
        setError(false);
      } else {
        toast.error("Failed to upload audio file.");
      }
    } catch (error) {
      console.error("There was an error uploading the audio file!", error);
      toast.error("Error uploading audio file: " + error.message);
    }
  };

  const handleAddStoryOpen = () => {
    setOpenAddStory(true);
  };

  const handleAddStoryClose = () => {

    setOpenAddStory(false);
    setSelectedMainCategory('');
    setSelectedShop('');
    setStoryList([{ type: '', product: '', offer: '', other: '' }]);
    setSelectedCategory('');
    setSelectedShop('');
    setStoryName('');
    setProfileImage(null);
    setStoryImages([]);
    setStoryMetaData([{ key: '', type: '' }]);
    setSelectedAudio(null);
  };

  const handleAddStorySave = () => {
    const form = new FormData();
    if (selectedMainCategory === 'shop') {
      const selectedShopData = shops.find(shop => shop.name === selectedShop);
      if (selectedShopData) {
        form.append("name", selectedShopData.name);
        form.append("profileImageURL", selectedShopData.shopImg);
        form.append("id", selectedShopData._id);
      }
    } else {
      form.append("name", storyName);
      form.append("profileImage", profileImage);
      storyImages.forEach((image, index) => {
        form.append(`storyImages`, image);
        form.append(`storyMetaData[${index}][type]`, 'other');
      });
    }
    const selectedAudioData = audioFiles.find(audio => audio._id === selectedAudio._id);
    if (selectedAudioData) {
      console.log(selectedAudioData, 'selectedAudioData');
      form.append("audio", selectedAudioData._id);
      form.append("audioURL", selectedAudioData.audioURL);
    }
    let currentIndex = 0;

    storyList.forEach((story) => {
      if (story.type === 'product') {
        const selectedProductData = products.find(product => product._id === story.product);
        if (selectedProductData) {
          form.append(`storyMetaData[${currentIndex}][image]`, selectedProductData.thumbnailURL);
          form.append(`storyMetaData[${currentIndex}][key]`, selectedProductData._id);
          form.append(`storyMetaData[${currentIndex}][type]`, 'product');
          currentIndex++;
        }
      }
      if (story.type === 'offer') {
        const selectedOfferData = offers.find(offer => offer._id === story.offer);
        if (selectedOfferData) {
          form.append(`storyMetaData[${currentIndex}][image]`, selectedOfferData.imgURL);
          form.append(`storyMetaData[${currentIndex}][key]`, selectedOfferData._id);
          form.append(`storyMetaData[${currentIndex}][type]`, 'offer');
          currentIndex++;
        }
      }
      if (story.type === 'other') {
        storyImages.forEach((image) => {
          form.append(`storyImages`, image);
          form.append(`storyMetaData[${currentIndex}][type]`, 'other');
          currentIndex++;
        });
    }
  });
  

    axios.post(backendApiUrl + "/stories/", form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log(response.data);
        handleAddStoryClose();
        fetchStories();
      })
      .catch(error => {
        console.error("There was an error adding the story!", error);
      });
  };

  // console.log(selectedAudio, 'eeeeeeeeeee');

  const handleAddStoryField = () => {
    setStoryList([...storyList, { type: '', product: '', offer: '', other: '' }]);
  };

  const handleRemoveStoryField = (index) => {
    const newStoryList = storyList.filter((_, i) => i !== index);
    setStoryList(newStoryList);
  };

  const handleStoryImageChange = (e) => {
    setStoryImages([...storyImages, e.target.files[0]]);
  };

  const handleProfileImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleStoryMetaDataChange = (index, field, value) => {
    const newMetaData = [...storyMetaData];
    newMetaData[index][field] = value;
    setStoryMetaData(newMetaData);
  };

  const handleCategoryChange = (e) => {
    setSelectedMainCategory(e.target.value);
  };

  const handleShopChange = (e) => {
    setSelectedShop(e.target.value);
    const selectedShopData = shops.find(shop => shop.name === e.target.value);
    if (selectedShopData) {
      fetchProducts(selectedShopData._id);
      fetchOffers(selectedShopData._id);
    }
  };

  const handleTypeChange = (e) => {
    setSelectedCategory(e.target.value);
    if (e.target.value === 'product') {
      const selectedShopData = shops.find(shop => shop.name === selectedShop);
      if (selectedShopData) {
        fetchProducts(selectedShopData._id);
      }
    } else if (e.target.value === 'offer') {
      const selectedShopData = shops.find(shop => shop.name === selectedShop);
      if (selectedShopData) {
        fetchOffers(selectedShopData._id);
      }
    }
  };

  const handleRemoveStoryImage = (index) => {
    const newStoryImages = storyImages.filter((_, i) => i !== index);
    setStoryImages(newStoryImages);
  };

  const handleRemoveStoryMetaData = (index) => {
    const newMetaData = storyMetaData.filter((_, i) => i !== index);
    setStoryMetaData(newMetaData);
  };

  const handleAudioFileChange = (e) => {
    setAudioFile(e.target.files[0]);
  };

  const handleDeleteStory = async (storyId) => {
    const settings = {
      url: `${backendApiUrl}/stories/${storyId}`,
      method: "DELETE",
      headers: {
        "Authorization": "null"
      }
    };

    try {
      const response = await axios(settings);
      console.log(response.data);
      toast.success("Story deleted successfully!");
      fetchStories();
    } catch (error) {
      console.error("There was an error deleting the story!", error);
      toast.error("Error deleting story: " + error.message);
    }
  };

  const columns = [
    {
      label: 'Name',
      dataField: 'name',
      dataType: 'string',
      width: 200,
      align: 'center'
    },
    {
      label: 'Audio',
      dataField: 'audioURL',
      dataType: 'string',
      width: 150,
      align: 'center',
      formatFunction(settings) {
        settings.template = `<audio controls src="${settings.value}" style="width: 100%;"></audio>`;
      }
    },
    {
      label: 'Image',
      dataField: 'profileImageURL',
      dataType: 'string',
      width: 50,
      formatFunction(settings) {
        settings.template = `<img src="${settings.value}" alt="Shop Image" width="40" />`;
      }
    },
    {
      label: 'Story Images',
      dataField: 'story',
      dataType: 'any',
      width: 200,
      formatFunction(settings) {
        if (Array.isArray(settings.value)) {
          settings.template = settings.value.map(storyItem => `
            <div style="display: flex; align-items: center; margin-bottom: 10px;">
              <img src="${storyItem.image}" alt="Story Image" width="40" style="margin-right: 10px;" />
              <div>
                <div>Key: ${storyItem.key || 'N/A'}</div>
                <div>Type: ${storyItem.type || 'N/A'}</div>
              </div>
            </div>
          `).join('');
        } else {
          settings.template = 'No stories available';
        }
      }
    },
    {
      label: 'Actions',
      dataField: '_id',
      dataType: 'string',
      width: 70,
      formatFunction(settings) {
        settings.template = `<button onclick="handleDeleteStory('${settings.value}')">Delete</button>`;
      }
    }
  ];

  useEffect(() => {
    window.handleDeleteStory = handleDeleteStory;
  }, []);



  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <Button sx={{ position: 'absolute', right: 20 }} variant="outlined" onClick={handleClickOpen}>Manage Audio</Button>
        <Button sx={{ position: 'absolute', right: 200 }} variant="outlined" onClick={handleAddStoryOpen}>Add Story</Button>
        <h1>Highlight Story</h1>
      </div>
      <div style={{ padding: '100px' }}>
        <Table
          id="table"
          ref={tableRef}
          dataSource={stories}
          keyboardNavigation={keyboardNavigation}
          columns={columns}
          className="custom-table table-hover thead-dark"
        />
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Manage Audio
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {audioFiles.length > 0 ? (
            audioFiles.map((audio) => (
              <div key={audio._id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <span style={{ flex: 0.2, marginRight: '10px' }}>{audio.name}</span>
                <audio controls src={audio.audioURL} style={{ flex: 0.7, marginRight: '10px' }} />
                <IconButton aria-label="delete" onClick={() => handleDelete(audio._id)} style={{ flex: 0.1 }}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ))
          ) : (
            <Typography>No audio files available</Typography>
          )}
          {showAddAudioFields ? (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Audio Name"
                type="text"
                fullWidth
                value={audioName}
                onChange={(e) => setAudioName(e.target.value)}
                error={error && !audioName}
                helperText={error && !audioName ? 'Audio name is required' : ''}
              />
              <input type="file" onChange={handleAudioFileChange} style={{ marginTop: '10px' }} />
              {audioFile && (
                <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
                  Selected file: {audioFile.name}
                </Typography>
              )}
              <DialogActions>
                <Button onClick={() => setShowAddAudioFields(false)} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSaveAudio} color="primary">
                  Save
                </Button>
              </DialogActions>
            </>
          ) : (
            <Button variant="outlined" onClick={handleAddAudioOpen} style={{ marginTop: '10px' }}>Add Audio</Button>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAddStory} onClose={handleAddStoryClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          Add Story
          <IconButton
            aria-label="close"
            onClick={handleAddStoryClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Select
              value={selectedMainCategory}
              onChange={handleCategoryChange}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>Select Category</MenuItem>
              <MenuItem value="shop">Shop</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {selectedMainCategory === 'shop' && (
              <>
                {/* <Select
                  value={selectedShop}
                  onChange={handleShopChange}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>Select Shop</MenuItem>
                  {shops.map((shop) => (
                    <MenuItem key={shop._id} value={shop.name}>
                      <img src={shop.shopImg} alt={shop.name} style={{ width: '30px', marginRight: '10px' }} />
                      {shop.name}
                    </MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  value={selectedShop}
                  onChange={(event, newValue) => {
                    setSelectedShop(newValue);
                    const selectedShopData = shops.find(shop => shop.name === newValue);
                    if (selectedShopData) {
                      fetchProducts(selectedShopData._id);
                      fetchOffers(selectedShopData._id);
                    }
                  }}
                  options={shops.map(shop => shop.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Shop"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => {
                    const shop = shops.find(shop => shop.name === option);
                    return (
                      <li {...props} key={shop._id}>
                        <img src={shop.shopImg} alt={shop.name} style={{ width: '30px', marginRight: '10px' }} />
                        {shop.name}
                      </li>
                    );
                  }}
                />
                        {storyList.map((story, index) => (
                          <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Select
                              value={story.type}
                              onChange={(e) => {
                                const newStoryList = [...storyList];
                                newStoryList[index].type = e.target.value;
                                setStoryList(newStoryList);
                              }}
                              displayEmpty
                              fullWidth
                            >
                              <MenuItem value="" disabled>Select Type</MenuItem>
                              <MenuItem value="product">Product</MenuItem>
                              <MenuItem value="offer">Offer</MenuItem>
                              <MenuItem value="other">Other</MenuItem>
                            </Select>
                            {story.type === 'product' && (
                              <Select
                                value={story.product}
                                onChange={(e) => {
                                  const newStoryList = [...storyList];
                                  newStoryList[index].product = e.target.value;
                                  setStoryList(newStoryList);
                                }}
                                displayEmpty
                                fullWidth
                              >
                                <MenuItem value="" disabled>Select Product</MenuItem>
                                {products.map((product) => (
                                  <MenuItem key={product._id} value={product._id}>
                                    <img src={product.thumbnailURL} alt={product.name} style={{ width: '30px', marginRight: '10px' }} />
                                    {product.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                            {story.type === 'offer' && (
                              <Select
                                value={story.offer}
                                onChange={(e) => {
                                  const newStoryList = [...storyList];
                                  newStoryList[index].offer = e.target.value;
                                  setStoryList(newStoryList);
                                }}
                                displayEmpty
                                fullWidth
                              >
                                <MenuItem value="" disabled>Select Offer</MenuItem>
                                {offers.map((offer) => (
                                  <MenuItem key={offer._id} value={offer._id}>
                                    {offer.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                            {story.type === 'other' && (
                              <>
                                <Typography variant="body2" color="textSecondary">
                                  Story Images
                                </Typography>
                                <input type="file" onChange={handleStoryImageChange} />
                                {storyImages.map((image, index) => (
                                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <img src={URL.createObjectURL(image)} alt="Story Image" width="40" />
                                    <IconButton aria-label="delete" onClick={() => handleRemoveStoryImage(index)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                ))}
                              </>

                              // <TextField
                              //   value={story.other}
                              //   onChange={(e) => {
                              //     const newStoryList = [...storyList];
                              //     newStoryList[index].other = e.target.value;
                              //     setStoryList(newStoryList);
                              //   }}
                              //   label="Other"
                              //   fullWidth
                              // />
                            )}
                            <Button variant="outlined" color="secondary" onClick={() => handleRemoveStoryField(index)}>
                              Remove Story
                            </Button>
                          </div>
                        ))}
                        <Button variant="outlined" onClick={handleAddStoryField}>
                          Add Another Story
                        </Button>
                      </>
                    )
                  }
            {selectedMainCategory === 'other' && (
                    <>
                      <TextField                    
                        value={storyName}
                        onChange={(e) => setStoryName(e.target.value)}
                        label="Story Name"
                        fullWidth
                      />
                      <Typography variant="body2" color="textSecondary">
                        Profile Image
                      </Typography>
                      <input type="file" onChange={handleProfileImageChange} />
                      {profileImage && (
                        <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
                          Selected file: {profileImage.name}
                        </Typography>
                      )}
                      <Typography variant="body2" color="textSecondary">
                        Story Images
                      </Typography>
                      <input type="file" onChange={handleStoryImageChange} />
                      {storyImages.map((image, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <img src={URL.createObjectURL(image)} alt="Story Image" width="40" />
                          <IconButton aria-label="delete" onClick={() => handleRemoveStoryImage(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ))}
                      {/* <TextField
                  value={storyMetaData[0].type}
                  onChange={(e) => handleStoryMetaDataChange(0, 'type', e.target.value)}
                  label="Type"
                  fullWidth
                /> */}
                      {/* <Button variant="outlined" onClick={() => handleRemoveStoryMetaData(0)}>
                  Remove Story
                </Button> */}
                    </>
                  )}
            <Select
                  value={selectedAudio}
                  onChange={(e) => setSelectedAudio(e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>Select Audio</MenuItem>
                  {audioFiles.map((audio) => (
                    <MenuItem key={audio._id} value={audio}>{audio.name}</MenuItem>
                  ))}
                </Select>
              </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddStoryClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddStorySave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HighlightStory;
