import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/actions/actions";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Chip // Import Chip component
} from "@mui/material";
import empty from "../../Components/Elements/Images/empty1.gif";
import { Table } from 'smart-webcomponents-react/table';
import { backendApiUrl } from "../../constants";
import "./AllBanner.css";
import { deleteShopBanner, updateShopBanner } from "../../API/ShopBannerApi";

const AllBanner = () => {
  const [originalData, setOriginalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [filterStatus, setFilterStatus] = useState('all');
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const handleLoading = (value) => {
    dispatch(setLoading(value));
    setIsLoading(value);
  };

  const fetchOffers = async () => {
    handleLoading(true);
    try {
      const response = await fetch(
        backendApiUrl+ "/all/shopBanners",
        { method: "GET" }
      );

      const data = await response.json();

      if (data.status === true) {
        // toast.success(data.message);
        if (data.data && data.data.length > 0) {
          const newData = data.data.map((item) => { 
            return {
              ...item,
              id: item._id,
              shopBanner: item.shopBanner ? item.shopBanner : "N/A",
              shopId: item.shopId ? item.shopId.name : "N/A",
              isActive : item.isActive ? item.isActive : false,
              isDeleted : item.isDeleted ? item.isDeleted : "N/A",
            }
          });
          setOriginalData(newData);

        } else {
          toast.error("No banner found.");
          setOriginalData([]);
        }
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(`Error fetching banner: ${err.message}`);
    } finally {
      handleLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);


  const handleDelete = async (id) => {
    handleLoading(true);
    try {
      const response = await deleteShopBanner(id);
      if (response.status === true) {
        toast.success("Banner Deleted");
        fetchOffers(); // Refresh the data
      } else {
        toast.error("Error Deleting Banner");
      }
    } catch (error) {
      toast.error(`Error deleting banner: ${error.message}`);
    } finally {
      handleLoading(false);
    }
  };

  const handleVerify = async (id, isActive) => {
    handleLoading(true);
    try {
      const data = { isActive: !isActive };
      const response = await updateShopBanner(id, data);
      if (response.status === true) {
        toast.success("Banner Updated");
        fetchOffers(); // Refresh the data
      } else {
        toast.error("Error Updating Banner");
      }
    } catch (error) {
      toast.error(`Error updating banner: ${error.message}`);
    } finally {
      handleLoading(false);
    }
  };

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
  };

  const handleConfirmDelete = () => {
    handleDelete(selectedId);
    handleClose();
  };

  useEffect(() => {
    window.handleClickOpen = handleClickOpen;
    window.handleVerify = handleVerify;
  }, []);

  const handleFilterChange = (status) => {
    setFilterStatus(status);
  };

  const filteredData = originalData.filter(item => {
    if (filterStatus === 'all') return true;
    return filterStatus === 'active' ? item.isActive : !item.isActive;
  });

  const grouping = true;
  const keyboardNavigation = true;
  const columns = [
    {
      label: 'Banner Image',
      dataField: 'shopBanner',
      dataType: 'string',
      formatFunction(settings) {
        settings.template = `<img src="${settings.value}" alt="Banner Image" width="100" />`;
      }
    },
    {
      label: 'Shop Name',
      dataField: 'shopId',
      dataType: 'string',
      formatFunction(settings) {
        settings.template = settings.value || 'N/A';
      },
    },
    {
      label: 'Actions',
      dataField: 'id',
      dataType: 'string',
      formatFunction(settings) {
        const id = settings.value;
        const isActive = settings.data.isActive; // Access isActive from the data object
        console.log('isActive', isActive);
        settings.template = `
          <span class="verified-tag" onclick="handleVerify('${id}', ${isActive})">${isActive ? 'Active' : 'In Active'}</span>
          <button class="edit-button" onclick="handleClickOpen('${id}')">Delete</button>
        `;
      }
    }
  ];

  const init = () => {
    const table = tableRef.current;
    if (table) {
      table.clearGrouping();
      table.addGroup('shopId'); 
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      init();
    }
  }, [filteredData]); 

  return (
    <>
      <Box>
        <Typography
          variant="h3"
          align="center"
          fontWeight="600"
          marginTop="5vh">
          Shop Banners
        </Typography>
      </Box>
      <Box display="flex" marginX="90px" alignItems="center">
        <Typography style={{ padding: '5px' }}>Status:</Typography>
        <Chip
          label="All"
          clickable
          color={filterStatus === 'all' ? 'primary' : 'default'}
          onClick={() => handleFilterChange('all')}
          style={{ margin: '0 5px' }}
        />
        <Chip
          label="Active"
          clickable
          color={filterStatus === 'active' ? 'primary' : 'default'}
          onClick={() => handleFilterChange('active')}
          style={{ margin: '0 5px' }}
        />
        <Chip
          label="Inactive"
          clickable
          color={filterStatus === 'inactive' ? 'primary' : 'default'}
          onClick={() => handleFilterChange('inactive')}
          style={{ margin: '0 5px' }}
        />
      </Box>
      {filteredData.length > 0 ? (
        <Box paddingX={12}>
          <div>
            <Table ref={tableRef} id="table" dataSource={filteredData} 
              keyboardNavigation={keyboardNavigation} columns={columns}  filtering tooltip columnResize sortMode="one" paging className="custom-table table-hover  thead-dark"></Table>
          </div>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh">
          <Box textAlign="center">
            <Typography variant="h6">No banner found</Typography>
            <img src={empty} width="500px" alt="No Products" />
          </Box>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this banner?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AllBanner;
