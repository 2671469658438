/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { getImage, uploadImage } from "../../API/CommonImageApi"; // Adjust the import path as needed
import { setLoading } from "../../store/actions/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, TextField, Typography, TextareaAutosize, Dialog, DialogTitle, DialogContent, DialogActions, Switch } from "@mui/material";
import { backendApiUrl } from "../../constants";
import axios from "axios";

const GlobalAsset = () => {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [img, setImg] = useState({ preview: "", raw: "" });
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [cashbackInfo, setCashbackInfo] = useState({ cashbackInfo: "" , cashBackText: true});
  const [jsonInput, setJsonInput] = useState("");
  const [jsonSubmitted, setJsonSubmitted] = useState(false);
  const [footerJsonInput, setFooterJsonInput] = useState("");
  const [footerJsonSubmitted, setFooterJsonSubmitted] = useState(false);
  const [splashscreenJsonInput, setSplashscreenJsonInput] = useState("");
  const [splashscreenJsonSubmitted, setSplashscreenJsonSubmitted] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dynamicImages, setDynamicImages] = useState([]);
  const [cashBackText, setCashbackText] = useState({});
   const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("Name:", name, "Value:", value);
    setCashbackInfo((prevCashbackInfo) => ({
      ...prevCashbackInfo,
      [name]: value,
    }));
  };

  const handleJsonChange = (e) => {
    setJsonInput(e.target.value);
  };

  const handleFooterJsonChange = (e) => {
    setFooterJsonInput(e.target.value);
  };

  const handleSplashscreenJsonChange = (e) => {
    setSplashscreenJsonInput(e.target.value);
  };

  const fetchJsonData = async (url, setState) => {
    try {
      const response = await axios.get(url);
      if (response.data) {
        setState(JSON.stringify(response.data.result, null, 2));
      }
    } catch (err) {
      console.error(`Failed to fetch data from ${url}:`, err);
    }
  };

  const handleJsonSubmit = async () => {
    if (!jsonInput.trim()) {
      toast.error("JSON input cannot be empty");
      return;
    }
    try {
      const response = await axios.put(
        `${backendApiUrl}/common/headerjson`,
        JSON.parse(jsonInput),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Header JSON data updated successfully");
      console.log(response.data);
      setJsonSubmitted(true);
      setTimeout(() => {
        setJsonSubmitted(false);
        // setJsonInput("");
      }, 5000);
      fetchJsonData(`${backendApiUrl}/common/headerjson`, setJsonInput);
    } catch (err) {
      toast.error("Invalid JSON Format...");
      console.error(err);
    }
  };

  const handleFooterJsonSubmit = async () => {
    if (!footerJsonInput.trim()) {
      toast.error("Footer JSON input cannot be empty");
      return;
    }
    try {
      const response = await axios.put(
        `${backendApiUrl}/common/footerjson`,
        JSON.parse(footerJsonInput),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Footer JSON data updated successfully");
      console.log(response.data);
      setFooterJsonSubmitted(true);
      setTimeout(() => {
        setFooterJsonSubmitted(false);
        // setFooterJsonInput("");
      }, 5000);
      fetchJsonData(`${backendApiUrl}/common/footerjson`, setFooterJsonInput);
    } catch (err) {
      toast.error("Invalid JSON Format...");
      console.error(err);
    }
  };

  const handleSplashscreenJsonSubmit = async () => {
    if (!splashscreenJsonInput.trim()) {
      toast.error("Splashscreen JSON input cannot be empty");
      return;
    }
    try {
      const response = await axios.put(
        `${backendApiUrl}/common/splashjson`,
        JSON.parse(splashscreenJsonInput),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Splashscreen JSON data updated successfully");
      console.log(response.data);
      setSplashscreenJsonSubmitted(true);
      setTimeout(() => {
        setSplashscreenJsonSubmitted(false);
        // setSplashscreenJsonInput("");
      }, 5000);
      fetchJsonData(`${backendApiUrl}/common/splashjson`, setSplashscreenJsonInput);
    } catch (err) {
      toast.error("Invalid JSON Format...");
      console.error(err);
    }
  };

  useEffect(() => {
    // Fetch all images on component mount
    const fetchImages = async () => {
      try {
        const imageResponses = await getImage()
          .then((images) => {
            return images;
          })
          .then((filteredImages) => {
            if (filteredImages.status === true) {
              setImages([filteredImages.data]);
              console.log("Images:", images);
              setCashbackInfo({
                cashbackInfo: filteredImages.data.cashBackInfo,
                cashBackText: filteredImages.data.cashBackText,
              });
              toast.success(filteredImages.message);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } catch (err) {
        setError("Failed to fetch images.");
      }
    };

    fetchImages();
    fetchJsonData(`${backendApiUrl}/common/headerjson`, setJsonInput);
    fetchJsonData(`${backendApiUrl}/common/footerjson`, setFooterJsonInput);
    fetchJsonData(`${backendApiUrl}/common/splashjson`, setSplashscreenJsonInput);
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const cleanedFile = new File([file], file.name.replace(/\s+/g, ""), {
        type: file.type,
      });

      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleFileChangeforCommon = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const cleanedFile = new File([file], file.name.replace(/\s+/g, ""), {
        type: file.type,
      });

      setImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleUpload = async () => {
    // if (!img.raw) {
    //   console.error("No file selected");
    //   return;
    // }
    handleLoading(true);

    const formData = new FormData();
    if (img.raw) {
      formData.append("globalasset", img.raw);
    }
    formData.append("cashBackInfo", cashbackInfo.cashbackInfo);
    formData.append("cashBackText", cashbackInfo.cashBackText); // Add cashback text (true/false)


    try {
      const response = await fetch(
        backendApiUrl + "/common/image",
        {
          method: "PUT",
          body: formData,
        }
      );

      const data = await response.json();
      if (data.status === true) {
        handleLoading(false);
        toast.success(data.message);
        setImg({ preview: "", raw: "" });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        handleLoading(false);
        toast.error(data.message);
      }
    } catch (err) {
      handleLoading(false);
      toast.error(err.message);
    }
  };

  const clearCache = async () => {
    try {
      const response = await axios.get(`${backendApiUrl}/common/clearcache`);
      if (response) {
        toast.success('Cache ClearSuccess');
        console.log(response)
      } else {
        toast.error(response);
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to clear cache.");
    }
  };

  const handleCurlUpload = async () => {
    if (!image.raw) {
      toast.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("dynamicImage", image.raw);

    try {
      const response = await fetch(`${backendApiUrl}/all/dynamicimages`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        toast.success("Image uploaded successfully");
        setImage({ preview: "", raw: "" });
        console.log(data);
      } else {
        toast.error("Failed to upload image");
        console.error(data);
      }
    } catch (err) {
      toast.error("Error uploading image");
      console.error(err);
    }
  };

  const handleViewImages = async () => {
    try {
      const response = await fetch(`${backendApiUrl}/all/dynamicimages`);
      const data = await response.json();
      if (data.status) {
        setDynamicImages(data.data);
        setDialogOpen(true);
      } else {
        toast.error("Failed to fetch dynamic images");
      }
    } catch (err) {
      toast.error("Error fetching dynamic images");
      console.error(err);
    }
  };

  const handleDeleteImage = async (id) => {
    try {
      const response = await fetch(`${backendApiUrl}/all/dynamicimages/${id}`, {
        method: "DELETE",
      });
      const data = await response.json();
      if (response.ok) {
        toast.success("Image deleted successfully");
        setDynamicImages(dynamicImages.filter(image => image._id !== id));
      } else {
        toast.error("Failed to delete image");
        console.error(data);
      }
    } catch (err) {
      toast.error("Error deleting image");
      console.error(err);
    }
  };

  return (
    <>
      <div style={{ position: "absolute", top: "20px", right: "20px" }}>
        <Button onClick={clearCache} className="bg-blue-500 hover:bg-blue-700 text-white font-bold">
          Clear Cache
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // justifyContent: "center",
          // minHeight: "100vh",
        }}>

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <h1>Image Upload</h1>

          {error && <p style={{ color: "red" }}>{error}</p>}
          {img && <img src={img.preview} alt="Selected preview" width="50px" />}

          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}>
            <input type="file" onChange={handleFileChange} />
            <TextField
              id="outlined-basic"
              label="Update Cashback Info"
              variant="outlined"
              type="number"
              name="cashbackInfo"
              value={cashbackInfo.cashbackInfo}
              style={{ margin: "10px" }}
              onChange={handleChange}
            />
            <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
              <Typography variant="body1" style={{ marginRight: "10px" }}>
                Cashback Type:
              </Typography>
              <Switch
                checked={cashbackInfo.cashBackText == true}
                onChange={(e) =>
                  setCashbackInfo((prev) => ({
                    ...prev,
                    cashBackText: e.target.checked ? true : false,
                  }))
                }
                color="primary"
              />
              <Typography variant="body1" style={{ marginLeft: "10px" }}>
                {cashbackInfo.cashBackText === true ? "Flat" : "Upto"}
              </Typography>
            </div>
            <Button onClick={handleUpload} variant="contained" color="primary">
              {uploading ? "Uploading..." : "Upload"}
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <h2>Uploaded Images</h2>
          <div
            style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {images.map((image, index) => (
              <>
                <div key={index} style={{ margin: "10px" }}>
                  <img
                    src={image.imgURL} // Replace with the correct field for the image URL
                    alt={`Image ${index + 1}`}
                    style={{ height: "150px", objectFit: "cover" }}
                  />
                  <Typography variant="body1">
                    Current Cashback Info {image.cashBackInfo}
                  </Typography>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <h2>Upload Image for Dynamic</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input type="file" onChange={handleFileChange} style={{ marginBottom: "10px" }} />
          {image.preview && <img src={image.preview} alt="Selected preview" width="50px" style={{ marginLeft: "10px" }} />}
        </div>
        <Button onClick={handleCurlUpload} variant="contained" color="secondary" style={{ marginTop: "10px" }}>
          Upload
        </Button>

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <Button onClick={handleViewImages} variant="contained" color="primary" style={{ marginTop: "10px" }}>
            View Uploaded Images
          </Button>
        </div>
      </div>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Uploaded Images</DialogTitle>
        <DialogContent dividers style={{ maxHeight: "80vh", overflowY: "auto" }}>
          {dynamicImages.map((image) => (
            <div key={image._id} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <img src={image.imgURL} alt={image.name} width="100px" style={{ marginRight: "10px" }} />
              <Typography variant="body1" style={{ flex: 1 }}>{image.imgURL}</Typography>
              <Button onClick={() => handleDeleteImage(image._id)} variant="contained" color="secondary">
                Delete
              </Button>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <h2>Update JSON Data for Headers</h2>
        {jsonSubmitted ? (
          <Typography variant="body1" style={{ color: "green" }}>
            JSON Updated Successfully
          </Typography>
        ) : (
          <>
            <TextareaAutosize
              minRows={8}
              placeholder="Enter JSON data here"
              style={{ width: "80%", margin: "10px" }}
              value={jsonInput}
              onChange={handleJsonChange}
            />
            <Button onClick={handleJsonSubmit} variant="contained" color="primary" disabled={!jsonInput.trim()}>
              Submit JSON
            </Button>
          </>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <h2>Update JSON Data for Footers</h2>
        {footerJsonSubmitted ? (
          <Typography variant="body1" style={{ color: "green" }}>
            JSON Updated Successfully
          </Typography>
        ) : (
          <>
            <TextareaAutosize
              minRows={8}
              placeholder="Enter JSON data here"
              style={{ width: "80%", margin: "10px" }}
              value={footerJsonInput}
              onChange={handleFooterJsonChange}
            />
            <Button onClick={handleFooterJsonSubmit} variant="contained" color="primary" disabled={!footerJsonInput.trim()}>
              Submit JSON
            </Button>
          </>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <h2>Update JSON Data for Splashscreen</h2>
        {splashscreenJsonSubmitted ? (
          <Typography variant="body1" style={{ color: "green" }}>
            JSON Updated Successfully
          </Typography>
        ) : (
          <>
            <TextareaAutosize
              minRows={8}
              placeholder="Enter JSON data here"
              style={{ width: "80%", margin: "10px" }}
              value={splashscreenJsonInput}
              onChange={handleSplashscreenJsonChange}
            />
            <Button onClick={handleSplashscreenJsonSubmit} variant="contained" color="primary" disabled={!splashscreenJsonInput.trim()}>
              Submit JSON
            </Button>
          </>
        )}
      </div>


    </>
  );
};

export default GlobalAsset;
