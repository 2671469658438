/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  TableContainer,
  Paper,
  // Table,
  // TableHead,
  // TableRow,
  // TableCell,
  // TableBody,
} from "@mui/material";
import DiscountIcon from "@mui/icons-material/Discount";
import StoreIcon from "@mui/icons-material/Store";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShopIcon from "@mui/icons-material/Shop";
import empty from "../Elements/Images/empty1.gif";
import { getUnverifiedProducts, verifyProduct, deleteProduct } from "../../API/ProductApi";
import { getUnverifiedOffers } from "../../API/OfferApi";
import { getUnferiviedShops } from "../../API/ShopApi";
import UnVerifiedProductRow from "./ListItem/UnVerifiedProductRow";
import UnVerifiedOfferRow from "./ListItem/UnVerifiedOfferRow";
import ProductItem from "./ListItem/ProductItem";
import UnVerifiedShopRow from "./ListItem/UnVerifiedShopRow";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setValue } from "../../store/actions/actions";
import { setLoading } from "../../store/actions/actions";
import Sort from "../Elements/Sort/Sort";
import { Table } from 'smart-webcomponents-react/table';
import axios from "axios";
import { backendApiUrl } from "../../constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// const handleEditProduct = (shopId, productId) => {
//   console.log("Edit clicked for Product ID:", productId);
//   // Redirect to edit page
//   navigate(`/admin/verifiedShopsList/showProducts/${shopId}/${productId}`);
// };

const handleVerifyProduct = async (id) => {
  try {
    const formData = new FormData();
    formData.append("isVerified", true);
    const response = await axios.put(backendApiUrl + `/product/${id}/verify`, formData);
    if (response.data.status === true) {
      toast.success("Product verified successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error("Failed to verify product.");
    }
  } catch (error) {
    toast.error("Error verifying product: " + error.message);
  }
};

const handleDeleteProduct = async (id, userId) => {
  try {
    const response = await axios.delete(backendApiUrl + `/product/${id}`, {
      data: { userId },
    });
    if (response.data.status === true) {
      toast.success("Product deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error("Failed to delete product.");
    }
  } catch (error) {
    toast.error("Error deleting product: " + error.message);
  }
};

const handleVerifyOffer = async (id) => {
  try {
    const formData = new FormData();
    formData.append("isVerified", true);
    const response = await axios.put(backendApiUrl + `/offers/${id}`, formData);
    if (response.data.status === true) {
      toast.success("Offer verified successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error("Failed to verify offer.");
    }
  } catch (error) {
    toast.error("Error verifying offer: " + error.message);
  }
};

const handleDeleteOffer = async (id) => {
  try {
    await axios.delete(backendApiUrl + `/offers/${id}`);
    toast.success("Offer deleted successfully!");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    // fetchData();
  } catch (error) {
    toast.error("Error deleting offer: " + error.message);
  }
};

export default function NewRequestList() {
  const navigate = useNavigate(); // Use the useNavigate hook
  const [products, setProducts] = useState([]);
  const [offers, setOffers] = useState([]);
  const [shops, setShops] = useState([]);
  const [verifiedProducts, setVerifiedProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const value = useSelector((state) => state.value.value);
  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  const handleChange = (event, value) => {
    dispatch(setValue(value || "Offers"));
    setFilteredData([]);
  };

  const { shopId } = useParams(); // Destructure shopId from useParams

  const fetchData = () => {
    getUnverifiedProducts()
      .then((response) => response.data)
      .then((data) => {
        // Filter and sort products
        if (data.status == true) {
          handleLoading(false);
          return;
        }
        const filteredAndSortedProducts = data
          .filter((d) => d.isVerified === false)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        const verifiedAndSortedProducts = data
          .filter((d) => d.isVerified === true)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setProducts(filteredAndSortedProducts);
        setVerifiedProducts(verifiedAndSortedProducts);
      })
      .catch((error) => console.error("Error fetching products:", error));

    getUnverifiedOffers()
      .then((response) => response.data)
      .then((data) => {
        // Filter and sort offers
        const filteredAndSortedOffers = data
          .filter((d) => d.isVerified === false)
          .sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));
        const offersWithShopName = filteredAndSortedOffers.map((offer) => ({
          ...offer,
          shopName: offer.shopId.name,
        }));
        setOffers(offersWithShopName);
      })
      .catch((error) => console.error("Error fetching offers:", error));

    getUnferiviedShops()
      .then((response) => response.data)
      .then((data) => {
        // Filter and sort shops
        const filteredAndSortedShops = data
          .filter((d) => d.isVerified === false)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setShops(filteredAndSortedShops);
      })
      .catch((error) => console.error("Error fetching shops:", error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditProduct = (shopId, productId) => {
    console.log("Edit clicked for Product ID:", productId);
    console.log("Edit clicked for Product ID:", shopId);
    // Redirect to edit page
    navigate(`/admin/verifiedShopsList/showProducts/${shopId}/${productId}`);
  };

  const handleEditOffer = (shopId, offerId) => {
    console.log("Edit clicked for Offer ID:", offerId);
    // Redirect to edit page
    navigate(`/admin/verifiedShopsList/editOffers/${shopId}/${offerId}`);
  };

  // Make functions globally accessible within the component
  window.handleEditProduct = handleEditProduct;
  window.handleVerifyProduct = handleVerifyProduct;
  window.handleDeleteProduct = handleDeleteProduct;
  window.handleEditOffer = handleEditOffer;
  window.handleVerifyOffer = handleVerifyOffer;
  window.handleDeleteOffer = handleDeleteOffer;

  const verified = (id) => {
    handleLoading(true);
    const formData = new FormData();
    formData.append("isVerified", true);

    verifyProduct(id, formData)
      .then((response) => {
        if (response.status === true) {
          handleLoading(false);
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          handleLoading(false);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.error("Error verifying product:", error);
      });
  };

  const erase = async (id) => {
    handleLoading(true);
    deleteProduct(id)
      .then((response) => {
        if (response.status === false) {
          handleLoading(false);
          toast.error(response.message);
        } else {
          handleLoading(false);
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
      });
  };

  // Log state changes for debugging
  useEffect(() => {
    console.log("Products updated:", products);
  }, [products]);

  useEffect(() => {
    console.log("Offers updated:", offers);
  }, [offers]);

  useEffect(() => {
    console.log("Shops updated:", shops);
  }, [shops]);

  const tableRef = useRef(null);
  const grouping = true;
  const keyboardNavigation = true;
  const productColumns = [
    {
      label: 'Image',
      dataField: 'thumbnailURL',
      dataType: 'string',
      formatFunction(settings) {
        settings.template = `<img src="${settings.value}" alt="Product Image" width="100" />`;
      }
    },
    {
      label: 'Product Name',
      dataField: 'name',
      dataType: 'string',
    },
    {
      label: 'Shop Name',
      dataField: 'shopName',
      dataType: 'string',
    },
    {
      label: 'Price',
      dataField: 'price',
      dataType: 'number',
    },
    {
      label: 'Discount',
      dataField: 'discount',
      dataType: 'number',
    },
    {
      label: 'MRP',
      dataField: 'mrp',
      dataType: 'number',
    },
    {
      label: 'Actions',
      dataField: '_id',
      dataType: 'string',
      formatFunction(settings) {
        const id = settings.value;

        const shopId = settings.data.shopId._id;  // Assuming shopId is available in the row data

        settings.template = `
          <div style="display: flex; gap: 10px;">
            <button class="edit-button" onclick="handleEditProduct('${shopId}', '${id}')" style="background-color: #4CAF50; color: white; border: none; padding: 5px 10px; cursor: pointer;">Edit</button>
            <button class="verify-button" onclick="handleVerifyProduct('${id}')" style="background-color: #008CBA; color: white; border: none; padding: 5px 10px; cursor: pointer;">Verify</button>
            <button class="delete-button" onclick="handleDeleteProduct('${id}')" style="background-color: #f44336; color: white; border: none; padding: 5px 10px; cursor: pointer;">Delete</button>
          </div>
        `;
      }
    }
  ];

  const offerColumns = [
    {
      label: 'Image',
      dataField: 'imgURL',
      dataType: 'string',
      formatFunction(settings) {
        settings.template = `<img src="${settings.value}" alt="Offer Image" width="100" />`;
      }
    },
    {
      label: 'Offer',
      dataField: 'discount',
      dataType: 'string',
    },
    {
      label: 'Shop Name',
      dataField: 'shopName',
      dataType: 'string',
    },
    {
      label: 'Actions',
      dataField: '_id',
      dataType: 'string',
      formatFunction(settings) {
        const id = settings.value;
        const shopId = settings.row.shopId; // Assuming shopId is available in the row data
        settings.template = `
          <div style="display: flex; gap: 10px;">
            <button class="edit-button" onclick="handleEditOffer('${shopId}', '${id}')" style="background-color: #4CAF50; color: white; border: none; padding: 5px 10px; cursor: pointer;">Edit</button>
            <button class="verify-button" onclick="handleVerifyOffer('${id}')" style="background-color: #008CBA; color: white; border: none; padding: 5px 10px; cursor: pointer;">Verify</button>
            <button class="delete-button" onclick="handleDeleteOffer('${id}')" style="background-color: #f44336; color: white; border: none; padding: 5px 10px; cursor: pointer;">Delete</button>
          </div>
        `;
      }
    }
  ];

    const init = () => {
      const table = tableRef.current;
      if (table) {
        table.clearGrouping();
        table.addGroup('shopName'); 
      }
    };
  
    useEffect(() => {
      if (tableRef.current) {
        init();
      }
    }, [filteredData]); 
  


  return (
    <>
      <Box width="100%" align="center" marginTop="5vh">
        <BottomNavigation
          sx={{ width: 500 }}
          showLabels
          value={value}
          onChange={(e, newValue) => handleChange(e, newValue)}>
          <BottomNavigationAction
            label="Unverified Offers"
            value="Offers"
            icon={<DiscountIcon />}
          />
          <BottomNavigationAction
            label="Unverified Products"
            value="Products"
            icon={<ShopIcon />}
          />
        </BottomNavigation>
      </Box>
      <Box>
        {value === "Products" ? (
          <>
            <div style={{paddingLeft: '100px' }}>
              <Table ref={tableRef} id="table" dataSource={filteredData.length > 0 ? filteredData : products} 
                keyboardNavigation={keyboardNavigation} columns={productColumns} filtering tooltip columnResize sortMode="one" paging className="custom-table table-hover thead-dark"></Table>
            </div>
          </>
        ) : value === "Offers" ? (
          <>
            <div style={{paddingLeft: '100px' }}>
              <Table ref={tableRef} id="table" dataSource={filteredData.length > 0 ? filteredData : offers} 
                keyboardNavigation={keyboardNavigation} columns={offerColumns} filtering tooltip columnResize sortMode="one" paging className="custom-table table-hover thead-dark"></Table>
            </div>
          </>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            height="100vh"
            justifyContent="center">
            <img src={empty} width="500px" alt="No Requests" />
          </Box>
        )}
      </Box>
    </>
  );
}
