/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
  TextField,
  Button,
  Autocomplete,
  Typography,
  IconButton,
  MenuItem,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useForm } from "react-hook-form";
import LocationPicker from "../Elements/LocationPicker/LocationPicker";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { createShopApi } from "../../API/ShopApi";
import { getProductCategories } from "../../API/CategoryApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/actions/actions";
import QuillEditor from "../Elements/RichEditor/QuillEditor";
export default function CreateShop() {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm();

  const [img, setImg] = useState({ preview: "", raw: "" });
  const [category, setCategory] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [isChoosingLocation, setIsChoosingLocation] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [isPriority, setIsPriority] = useState(false);
  const [bgImg, setBgImg] = useState({ preview: "", raw: "" });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [shopDesc, setShopDesc] = useState({ desc: "" });
  const [shopId, setShopId] = useState("");
  const [link, setLink] = useState({
    instagram: "",
    facebook: "",
    website: "",
  });
  const handleLink = (event) => {
    const { name, value } = event.target;
    setLink({ ...link, [name]: value });
  };
  const fetchCategories = async () => {
    try {
      const response = await getProductCategories();
      const data = response.data;
      setCategory(data);
      console.log(category, response);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    console.log(link);
  }, [link]);

  const handleChooseLocation = () => {
    setIsChoosingLocation(true);
  };

  const handleLocationSelect = (location) => {
    console.log("Selected location:", location);
    setCoordinates(location); // Update state asynchronously
    setIsChoosingLocation(false);
  };

  // Use useEffect to log the updated coordinates
  useEffect(() => {
    console.log("Updated coordinates:", coordinates);
  }, [coordinates]);

  const onImageChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileName = file.name.replace(/\s+/g, ""); // Remove all white spaces from the file name

      // Create a new File object with the cleaned file name
      const cleanedFile = new File([file], fileName, { type: file.type });

      setImg({
        preview: URL.createObjectURL(cleanedFile),
        raw: cleanedFile,
      });

      // handleOpen();
    }
  };

  const onImageChange1 = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileName = file.name.replace(/\s+/g, ""); // Remove all white spaces from the file name

      // Create a new File object with the cleaned file name
      const cleanedFile = new File([file], fileName, { type: file.type });

      setBgImg({
        preview: URL.createObjectURL(cleanedFile),
        raw: cleanedFile,
      });

      // handleOpen();
    }
  };

  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  useEffect(() => {
    console.log("hello", bgImg);
  }, [bgImg]);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    console.log(data);
    setSubmit(true);
    try {
      const formData = new FormData();
      formData.append("shopImage", img.raw);
      formData.append("shopBgImage", bgImg.raw);
      if (!coordinates.lat || !coordinates.lng) {
        formData.append("location[]", []);
      } else {
        formData.append("location[0]", coordinates.lat);
        formData.append("location[1]", coordinates.lng);
      }
      formData.append("itemCategories[0]", selectedCategories);
      formData.append("name", data.name);
      formData.append("desc", shopDesc.desc);
      formData.append("kartaName", data.kartaName);
      formData.append("email", data.email);
      formData.append("aadharNo", data.aadharNo);
      formData.append("panNo", data.panNo);
      formData.append("gstNo", data.gstNo);
      formData.append("mobile", data.mobile);
      formData.append("addressKey", data.addressKey);
      formData.append("address", data.address);
      formData.append("state", data.state);
      formData.append("city", data.city);
      formData.append("pincode", data.pincode);
      formData.append("isVerified", true);
      formData.append("isPriority", isPriority);

      let socialMediaIndex = 0;
      if (link.instagram) {
        formData.append(`socialMediaLinks[${socialMediaIndex}][type]`, "instagram");
        formData.append(`socialMediaLinks[${socialMediaIndex}][url]`, link.instagram);
        socialMediaIndex++;
      }
      if (link.facebook) {
        formData.append(`socialMediaLinks[${socialMediaIndex}][type]`, "facebook");
        formData.append(`socialMediaLinks[${socialMediaIndex}][url]`, link.facebook);
        socialMediaIndex++;
      }
      if (link.website) {
        formData.append("websiteLink", link.website);
      }

      console.log(Object.fromEntries(formData.entries()));
      const response1 = await createShopApi(formData);
      handleLoading(true);
      if (response1.status === true) {
        handleLoading(false);
        setSubmit(false);
        toast.success(response1.message);
        setShopId(response1.data._id);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        handleLoading(false);
        toast.error(response1.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmit(false);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          style={{ paddingBlock: "30px" }}
          sx={{
            display: "grid",
            justifyContent: "center",
            height: "auto",
            width: "100%",
            "& .MuiTextField-root": { width: "59ch" },
          }}>
          <h1 style={{ textAlign: "center" }}>Create your shop</h1>
          <div
            style={{
              marginInline: 1,
              maxWidth: "1080px",
            }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  label="Shop Name"
                  title="Shop Name"
                  required
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("name", {
                      required: "Name is required",
                      minLength: {
                        value: 3, // Minimum length of 3 characters
                        message: "Name must be at least 3 characters",
                      },
                    }),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="kartaName"
                  required
                  type="text"
                  label="Karta Name"
                  title="Karta Name"
                  error={!!errors.kartaName}
                  helperText={errors.kartaName?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("kartaName", {
                      required: "Karta Name is required",
                      minLength: {
                        value: 3, // Minimum length of 3 characters
                        message: "Karta Name must be at least 3 characters",
                      },
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <QuillEditor
                  value={shopDesc}
                  heading="Product Description *"
                  onStateChange={setShopDesc}
                  title="Product Description"
                  htmlKey="desc"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  required
                  type="text"
                  label="Email"
                  title="Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // Basic email pattern
                        message: "Invalid email address",
                      },
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <TextField
                  name="password"
                  type="password" // Change to "password" type for password fields
                  required
                  label="Password"
                  title="Password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8, // Minimum length of 8 characters
                        message: "Password must be at least 8 characters",
                      },
                    }),
                  }}
                /> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="aadharNo"
                  label="Aadhar Number"
                  type="number"
                  title="Aadhar Number"
                  error={!!errors.aadharNo}
                  helperText={errors.aadharNo?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("aadharNo", {
                      // required: "Aadhar Number is required",
                      pattern: {
                        value: /^[0-9]{12}$/, // Aadhar number must be 12 digits
                        message: "Aadhar number must be 12 digits",
                      },
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="panNo"
                  label="PAN Number"
                  title="PAN Number"
                  error={!!errors.panNo}
                  helperText={errors.panNo?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("panNo", {
                      // required: "PAN Number is required",
                      pattern: {
                        value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, // Valid PAN format
                        message: "Invalid PAN number",
                      },
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="gstNo"
                  label="GST Number"
                  title="GST Number"
                  error={!!errors.gstNo}
                  helperText={errors.gstNo?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("gstNo", {
                      // required: "GST Number is required",
                      pattern: {
                        value: /^[0-9A-Z]{15}$/, // Valid GST format
                        message: "Invalid GST number",
                      },
                    }),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="mobile"
                  label="Mobile Number"
                  type="number"
                  title="Mobile Number"
                  error={!!errors.mobile}
                  helperText={errors.mobile?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("mobile", {
                      required: "Mobile Number is required",
                      pattern: {
                        value: /^[0-9]{10}$/, // Mobile number must be 10 digits
                        message: "Mobile number must be 10 digits",
                      },
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="address"
                  required
                  label="Address"
                  title="Address"
                  error={!!errors.address}
                  helperText={errors.address?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("address", {
                      required: "Address is required",
                    }),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="state"
                  required
                  label="State"
                  title="State"
                  error={!!errors.state}
                  helperText={errors.state?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("state", {
                      required: "State is required",
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="city"
                  required
                  label="City"
                  title="City"
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("city", {
                      required: "City is required",
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="pincode"
                  required
                  label="Pin Code"
                  type="number"
                  title="Pin Code"
                  error={!!errors.pincode}
                  helperText={errors.pincode?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("pincode", {
                      required: "Pin Code is required",
                      pattern: {
                        value: /^[0-9]{6}$/, // Pin code must be 6 digits
                        message: "Pin code must be 6 digits",
                      },
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="Address Key"
                  required
                  label="Address Key"
                  // type="number"
                  title="Address Key"
                  error={!!errors.addressKey}
                  helperText={errors.addressKey?.message}
                  inputProps={{
                    // Apply schema validation rules
                    ...register("addressKey", {
                      required: "Address Key is required",
                      pattern: {
                        // value: /^[0-9]{6}$/, // Pin code must be 6 digits
                        message: "Address Key must be string",
                      },
                    }),
                  }}
                />
              </Grid>

              {/* Social Media Link */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="instagram"
                  label="Instagram Link"
                  title="Instagram Link"
                  onChange={handleLink}
                  helperText="Please enter your Instagram link"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="facebook"
                  label="Facebook Link"
                  title="Facebook Link"
                  onChange={handleLink}
                  helperText="Please enter your Facebook link"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="website"
                  label="Website Link"
                  title="Website Link"
                  onChange={handleLink}
                  helperText="Please enter your Website link"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="tags-outlined"
                  options={category}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    setSelectedCategories(value ? value._id : "");
                  }}
                  filterSelectedOptions
                  value={
                    category && category.length > 0
                      ? category.find((cat) => cat._id === selectedCategories)
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      title="Category"
                      label="Category"
                      required
                      placeholder="Select More"
                      error={!!errors.category}
                      helperText={errors.category?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset" sx={{ mb: 2 }}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isPriority}
                          onChange={(e) => setIsPriority(e.target.checked)}
                          color="primary"
                          inputProps={{ "aria-label": "Is Priority" }}
                        />
                      }
                      label="Is Premium"
                      labelPlacement="top"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </div>

          <div
            display="block"
            align="center"
            style={{
              marginTop: 24,
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography>Shop Image *</Typography>
            <input
              accept="image/*"
              title="Shop Image"
              type="file"
              {...register("shopImg", { required: "Shop Image is required" })}
              style={{ marginBottom: "10px" }}
              onChange={onImageChange}
            />
            <Box>
              {img.preview && <img width="100vh" src={img.preview} alt="." />}
            </Box>
            {/* image cropper to add here */}
            {errors.shopImg && (
              <p style={{ color: "red" }}>{errors.shopImg.message}</p>
            )}
            <Typography>Shop Background Image</Typography>
            <input
              accept="image/*"
              type="file"
              title="Shop Background Image"
              {...register("shopBgThumbnail", {
                required: "Shop Image is required",
              })}
              style={{ marginBottom: "10px" }}
              onChange={onImageChange1}
            />
            <img src={bgImg.preview} width="100px" />
            {/* <Modal
              style={{ overflow: "scroll" }}
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <ImageCropper
                images={[bgImg.preview]}
                setImages={setBgImg}
                onSaveImages={handleClose}
                multi={"false"}
              />
            </Modal> */}
            {errors.shopImg && (
              <p style={{ color: "red" }}>{errors.shopImg.message}</p>
            )}
            {isChoosingLocation ? (
              <>
                <Typography>Choose Location</Typography>
                <LocationPicker onSelectLocation={handleLocationSelect} />
              </>
            ) : (
              <>
                <Typography>Choose Location</Typography>
                <IconButton
                  color={
                    coordinates.lat && coordinates.lng ? "success" : "default"
                  }
                  onClick={handleChooseLocation}
                  sx={{ marginInline: "41%", width: "40px" }}>
                  <AddLocationIcon />
                </IconButton>
                {coordinates && coordinates.length > 1 && (
                  <Typography>
                    Selected coordinates: {coordinates.lat}, {coordinates.lng}
                  </Typography>
                )}
              </>
            )}
          </div>
          {shopId && (
            <Box
              align="center"
              sx={{ marginTop: 3, "& .MuiButton-root": { m: 1 } }}>
              <Typography variant="h6" marginTop="2vh">
                Vist this{" "}
                <a href={`/#/admin/verifiedShopsList/${shopId}`}>link</a> to
                update
              </Typography>
            </Box>
          )}
          <Box
            align="center"
            sx={{ marginTop: 3, "& .MuiButton-root": { m: 1 } }}>
            <Button variant="contained" color="success" type="submit">
              Create
            </Button>
          </Box>
        </Box>
      </form>
      {submit ? (
        <div style={{ zIndex: 3, display: "flex", backfaceVisibility: "70%" }}>
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
